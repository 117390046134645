import { Box, Grid } from "@mui/material";
import Images from "../../../../utils/Images";
import { boldText, CreateImage, CreateText, CreateTitle } from "./HowItWorkComponents";

export default function HowItWorkTeacher() {
  const createStaticImage = (image: string) => <Box component="img" sx={{ maxWidth: "70%", objectFit: "contain", padding: "0px 10px 0px 0px" }} alt="???" src={image} />;
  return (
    <Box sx={{ color: "white" }}>
      <CreateTitle isFirst title="Before the class" />
      <CreateText>
        Once you register as a organizer, you should create a course and corresponding lectures. If you are planning to use slides, please check if your presentation can be successfully uploaded to
        our platform (use a preview option). We currently support only a pdf format. We will not share your presentation with anyone (even students), and you can delete it at any time.
      </CreateText>

      <CreateTitle title="Just before the class starts" />
      <CreateText>
        Only students that attend a lecture (either virtually or physically) can register and get a pseudonym. At the beginning of the class, please request a Meeting Code and share it with students
        that are present. Students that already have an AnonID, should use it to login. Students that are joining the platform for the first time, should register.
        <br />
        <br />
        Please carefully monitor the number of issued AnonIDs and pseudonyms. If for example, the number of issued pseudonyms is larger than the number of students attending the meeting, you should
        reset the pseudonyms. That action deletes all the pseudonyms issued for that lecture.
        <br />
        <br />
        It is recommended that you keep the list of students that have already registered and allow students to register only for the first few lectures.
      </CreateText>

      <CreateTitle title="Student-Teacher Interaction" />
      <CreateImage width={20} image={Images.STUDENT_REMOTE} />
      <CreateText>
        The main mode of interaction during the class is through the remote control.
        <br />
        <br />
        It provides an anonymous and instant feedback to the organizer. To make sure that the feedback is minimally disruptive, the option to post questions, answers, or discuss is disabled during the
        lecture. However, you can enable it at any time if it is needed.
      </CreateText>
      <Grid container item xs={12} pt={1}>
        <CreateText>
          There are five options on the remote control.
          <ul>
            <li>{boldText("FF")} (Fast Forward), if the organizer should go faster; for example, if the material is too simple.</li>
            <li>{boldText("REW")} (Rewind), if the organizer is going too fast, and/or should go back and explain the material again.</li>
            <li>
              {boldText("Pause")}, if the student needs time to reflect. This is also a signal to the organizer to pause the meeting and allow students to ask questions (either through the platform or
              verbally).
            </li>
            <li>{boldText("I’m lost")}, if the student doesn’t understand the material.</li>
            <li>{boldText("Great")}, if everything is clear.</li>
          </ul>
        </CreateText>
      </Grid>
      <Grid container spacing={3} pt={1}>
        <Grid item xs={6} textAlign="center">
          <Box textAlign="left">
            <CreateText>If you are using slides, the responses from remote control can be seen in the bar under the slide.</CreateText>
          </Box>
          {createStaticImage(Images.PRESENTATION_VIEW)}
        </Grid>
        <Grid item xs={6} textAlign="center">
          <Box textAlign="left">
            <CreateText>If you are not using slides, the responses will be displayed on the remote control once you start the meeting</CreateText>
          </Box>
          {createStaticImage(Images.TEACHER_REMOTE)}
        </Grid>
      </Grid>

      <CreateTitle title="After the class is over" />
      <CreateImage width={20} image={Images.STATS_PREVIEW} />
      <CreateText>
        You can engage in Q/A/Ds and view statistics. For each slide, we collect the number of Q/A/Ds posted, and the average percentage of feedbacks for each expression from the remote control. The
        organizer can also view the detailed changes of feedbacks for each slide.
      </CreateText>
    </Box>
  );
}
