import { mdiChevronLeft } from "@mdi/js";
import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { INIT_PAGE_NUM, LAST_VIEWED_SLIDE } from "../../../../constants";
import SendInputMode from "../../../../constants/SendInputMode";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture, Question } from "../../../../controller";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import QuestionType from "../../../../interfaces/question/QuestionType";
import LectureNumParams from "../../../../utils/LectureNumParams";
import UserRole from "../../../../utils/UserRole";
import { sortListByItem, useEffectOnce } from "../../../../utils/Utils";
import { BackButton } from "../../../shared/components/styledComponents";
import QuestionCard from "../../../shared/question-card/QuestionCard";
import SelectCustom from "../../../shared/select-custom/SelectCustom";
import SendInput from "../../../shared/send-input/SendInput";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import { optionsSortBy } from "../../student-and-teacher/my-questions/config";

export default function TeacherQuestions() {
  const { selectedCourse } = useAppContext();
  const history = useHistory();
  const lectureNum = LectureNumParams();
  const { isTeacher } = UserRole();
  const [lastSlideViewed] = useState<number>(parseInt(localStorage.getItem(LAST_VIEWED_SLIDE) ?? INIT_PAGE_NUM.toString()));
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [lecture, setLecture] = useState<LectureDto | undefined>();
  const [sortBy, setSortBy] = useState<number>(optionsSortBy[0].id);
  const [text, setText] = useState<string>("");
  const handleSortBy = (e: SelectChangeEvent) => setSortBy(+e.target.value);
  const { isMobile } = useQueryMedia();

  useEffectOnce(() => {
    fetchData();
  });

  const handleSendQuestion = (teacherAsStudent: boolean) => {
    const teacherQuestion = isTeacher && !teacherAsStudent;
    Question.postQuestion(selectedCourse.id, lecture?.lectureNum, text, lastSlideViewed, teacherQuestion, isTeacher)
      .then((res: any) => {
        fetchData();
        setText("");
      })
      .catch((err: any) => console.log(err));
  };

  const getLecture = async () => {
    const lec = await Lecture.getLecture(selectedCourse.id, lectureNum);
    setLecture(lec);
  };

  const fetchData = () => {
    getLecture();
    Question.getQuestionsFromLecture(selectedCourse.id, lectureNum)
      .then(({ data }: any) => {
        const res = data.data;
        // const notFlagged = res.filter((q: QuestionType) => !q.flagged);
        // setQuestions(notFlagged);
        setQuestions(res);
      })
      .catch((error: any) => console.log(error));
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="center">
        <Grid container item xs={isMobile ? 12 : 7} justifyContent="space-between" mb={2}>
          <BackButton onClick={() => history.go(-1)} startIcon={<SvgIconWrapper icon={mdiChevronLeft} />} variant="text">
            Back
          </BackButton>
          <SelectCustom title="Sort by" value={sortBy} handleChange={handleSortBy} options={optionsSortBy} />
        </Grid>
      </Grid>
      <Grid container flexDirection="row" justifyContent="center" alignItems="flex-start">
        {questions.length === 0 && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="body1">There are currently no questions.</Typography>
          </Grid>
        )}
        <Grid container item justifyContent="center" alignItems="flex-start" alignContent="start" xs={12} sm={12} md={9} xl={7} lg={7} rowSpacing={1} minHeight={"400px !important"}>
          {sortListByItem(questions, sortBy).map((q: QuestionType) => (
            <Grid key={q.id} item xs={12}>
              <QuestionCard question={q} tabValue={0} callbackRefresh={fetchData} showEditable />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} sm={12} md={9} xl={7} lg={7} mt={1} flexDirection="column">
          <SendInput value={text} setText={setText} handleSend={handleSendQuestion} disabled={!lecture?.allowQuestionsDuringLecture} type={SendInputMode.Q} />
        </Grid>
      </Grid>
    </>
  );
}
