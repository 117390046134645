import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { remoteEvent } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AuthContext";
import { RemoteSocket } from "../../../../../controller";
import { SlideInteractions } from "../../../../../controller/SlideInteraction";
import ExpressionDto from "../../../../../interfaces/expression/ExpressionDto";
import LectureDto from "../../../../../interfaces/lecture/LectureDto";
import EnumExpressions from "../../../../../utils/enum/EnumExpressions";
import EnumExpressionsNum from "../../../../../utils/enum/EnumExpressionsNum";
import EnumExpressionsText from "../../../../../utils/enum/EnumExpressionsText";
import LectureNumParams from "../../../../../utils/LectureNumParams";
import MessageType from "../../../../../utils/MessageType";
import Routes from "../../../../../utils/Routes";
import UserRole from "../../../../../utils/UserRole";
import { mapExpressions } from "../../../../../utilsVue";

interface RemoteServiceExport {
  expressionState: { [key: number]: boolean };
  expressionData: ExpressionDto[];
  handleExpressionClick: any;
  handleResetClick: () => void;
  loadingReset: boolean;
  showSpeakerNameInput: boolean;
  setShowSpeakerNameInput: any;
  handleSetSpeakerName: () => void;
  speakerName?: string;
  setSpeakerName: any;
  currentSpeakerName?: string;
}

interface RemoteServiceProps {
  lecture: LectureDto;
  currentSlide: number;
  setCurrentSlide: (val: number) => void;
}

export default function RemoteService({ lecture, currentSlide, setCurrentSlide }: RemoteServiceProps): RemoteServiceExport {
  const { selectedCourse, showMessage } = useAppContext();
  const courseId = selectedCourse.id;
  const lectureNum = LectureNumParams();
  const { isTeacher, isStudent } = UserRole();
  const history = useHistory();

  const initExpression = {
    [EnumExpressionsText.LOST]: false,
    [EnumExpressionsText.PAUSE]: false,
    [EnumExpressionsText.GREAT]: false,
    [EnumExpressionsText.REW]: false,
    [EnumExpressionsText.FF]: false,
  };
  const [expressionState, setExpressionState] = useState<{ [key: string]: boolean }>(initExpression);
  const [allowReactions, setAllowReactions] = useState<boolean>(false);
  const [expressionData, setExpressionData] = useState<ExpressionDto[]>([]);
  const [loadingReset, setIsLoadingReset] = useState<boolean>(false);

  const [showSpeakerNameInput, setShowSpeakerNameInput] = useState<boolean>(false);
  const [speakerName, setSpeakerName] = useState<string>();

  const [currentSpeakerName, setCurrentSpeakerName] = useState<string>();

  useEffect(() => {
    const handleCurrentSlide = (slideNum: number) => {
      isTeacher && RemoteSocket.emit(remoteEvent.send.SET_SLIDE, slideNum);
      setCurrentSlide(slideNum);
    };
    const handleExpressionData = (data: any) => setExpressionData(parseExpressionData(data));
    const handleAllowReactions = (allow: boolean) => setAllowReactions(allow);
    const handleExpressionDeactivated = () => resetRemoteState();
    const handleNoActiveExpression = () => resetRemoteState();
    const handleExpressionOverload = ({ err }: any) => showMessage(err.toString(), MessageType.ERROR);
    const handleIsAttending = ({ err }: any) => err && handleConnectError(err);
    const handleResetExpressions = () => resetRemoteState();

    RemoteSocket.connect({ query: { courseId, lectureNum } });
    RemoteSocket.emit(remoteEvent.send.SET_ALLOW_REACTIONS, isTeacher);
    RemoteSocket.emit(remoteEvent.send.IS_ATTENDING, { courseId, lectureNum });
    RemoteSocket.emit(remoteEvent.send.GET_CURRENT_SLIDE);
    RemoteSocket.on(remoteEvent.recv.ACCESS_GRANTED, handleAccessGranted);
    RemoteSocket.on(remoteEvent.recv.CONNECT_ERROR, handleConnectError);
    RemoteSocket.on(remoteEvent.recv.ACTIVE_EXPRESSION, handleActiveExpression);
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_DEACTIVATED, handleExpressionDeactivated);
    RemoteSocket.on(remoteEvent.recv.SLIDE_CHANGED, handleSlideChange);
    RemoteSocket.on(remoteEvent.recv.SPEAKER_NAME_CHANGED, handleSpeakerNameChange);
    RemoteSocket.on(remoteEvent.recv.ALLOW_REACTIONS, handleAllowReactions);
    RemoteSocket.on(remoteEvent.recv.NO_ACTIVE_EXPRESSION, handleNoActiveExpression);
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_UPDATE, handleExpressionData);
    RemoteSocket.on(remoteEvent.recv.CURRENT_SLIDE, handleCurrentSlide);
    RemoteSocket.on(remoteEvent.recv.EXPRESSION_OVERLOAD, handleExpressionOverload);
    RemoteSocket.on(remoteEvent.recv.IS_ATTENDING_RES, handleIsAttending);
    RemoteSocket.on(remoteEvent.recv.RESETED_EXPRESSIONS, handleResetExpressions);
    return () => {
      RemoteSocket.emit(remoteEvent.send.SET_ALLOW_REACTIONS, isStudent);
      RemoteSocket.off(remoteEvent.recv.ACCESS_GRANTED, handleAccessGranted);
      RemoteSocket.off(remoteEvent.recv.CONNECT_ERROR, handleConnectError);
      RemoteSocket.off(remoteEvent.recv.ACTIVE_EXPRESSION, handleActiveExpression);
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_DEACTIVATED, handleExpressionDeactivated);
      RemoteSocket.off(remoteEvent.recv.SLIDE_CHANGED, handleSlideChange);
      RemoteSocket.off(remoteEvent.recv.SPEAKER_NAME_CHANGED, handleSpeakerNameChange);
      RemoteSocket.off(remoteEvent.recv.ALLOW_REACTIONS, handleAllowReactions);
      RemoteSocket.off(remoteEvent.recv.NO_ACTIVE_EXPRESSION, handleNoActiveExpression);
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_UPDATE, handleExpressionData);
      RemoteSocket.off(remoteEvent.recv.CURRENT_SLIDE, handleCurrentSlide);
      RemoteSocket.off(remoteEvent.recv.EXPRESSION_OVERLOAD, handleExpressionOverload);
      RemoteSocket.off(remoteEvent.recv.IS_ATTENDING_RES, handleIsAttending);
      RemoteSocket.on(remoteEvent.recv.RESETED_EXPRESSIONS, handleResetExpressions);
      RemoteSocket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lecture]);

  useEffect(() => {
    if (currentSlide) {
      SlideInteractions.getSlideInteractionsSpeakerName(courseId, lectureNum, currentSlide)
        .then(({ data }: any) => {
          setCurrentSpeakerName(data.data);
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    }
  }, [currentSlide]);

  const parseExpressionData = (data: any) => {
    const arrayOfExp = mapExpressions(data);
    return arrayOfExp.map((item: any) => {
      return { id: item[0], ...item[1] };
    });
  };

  const handleExpressionClick = (expStr: string) => {
    if (isStudent) {
      if (!allowReactions) {
        showMessage("Feedback not accepted. Organizator paused the meeting.", MessageType.ERROR);
      } else {
        const expNum = EnumExpressionsNum[expStr];
        RemoteSocket.emit(remoteEvent.send.PRESS_BUTTON, expNum);
      }
    }
  };

  const handleResetClick = async () => {
    const increment = currentSlide + 1;
    // RemoteSocket.emit(remoteEvent.send.RESET_EXPRESSIONS);
    setIsLoadingReset(true);
    setCurrentSlide(increment);
    RemoteSocket.emit(remoteEvent.send.SET_SLIDE, increment);
  };

  const handleSetSpeakerName = async () => {
    setSpeakerName(undefined);
    setShowSpeakerNameInput(false);
    RemoteSocket.emit(remoteEvent.send.SET_SPEAKER_NAME, { slideNumber: currentSlide ?? 1, speakerName });
  };

  const handleActiveExpression = ({ expressionTag, expiresAt, expressionDataAll }: any) => {
    setExpressionData(parseExpressionData(expressionDataAll));

    let expressionStateTemp: any = { ...initExpression };

    if (Object.keys(EnumExpressions).includes(expressionTag)) {
      const expressionText: string = EnumExpressions[expressionTag];
      expressionStateTemp[expressionText] = true;
    }
    setExpressionState(expressionStateTemp);
  };

  const handleAccessGranted = () => {
    console.log("Remote access granted");
    RemoteSocket.emit(remoteEvent.send.GET_EXPRESSIONS);
    isStudent && RemoteSocket.emit(remoteEvent.send.GET_ACTIVE_EXPRESSION);
  };

  const handleConnectError = (err: any) => {
    const msg = err.toString().replace("Error: ", "");
    history.replace(Routes.OLD_OR_NEW_STUDENT);
    showMessage(msg, MessageType.ERROR);
  };

  const resetRemoteState = () => {
    setExpressionState(initExpression);
    setTimeout(() => {
      setIsLoadingReset(false);
    }, 1500);
  };

  const handleSlideChange = (slideNum: any) => {
    resetRemoteState();
    setCurrentSlide(slideNum);
    RemoteSocket.emit(remoteEvent.send.GET_ACTIVE_EXPRESSION);
  };

  const handleSpeakerNameChange = (speakerName: any) => {
    setCurrentSpeakerName(speakerName);
  };

  return {
    expressionState,
    expressionData,
    handleExpressionClick,
    handleResetClick,
    loadingReset,
    showSpeakerNameInput,
    setShowSpeakerNameInput,
    speakerName,
    setSpeakerName,
    handleSetSpeakerName,
    currentSpeakerName,
  };
}
export type { RemoteServiceExport };
