const Images = {
  STUDENT_MASK: require("../assets/img/student-mask.png").default,
  STUDENT_REMOTE: require("../assets/img/student-remote.png").default,
  ICON_LOCK: require("../assets/img/icon-lock.png").default,
  LIVE_LECTURE_HEADER: require("../assets/img/live-lecture-header.png").default,
  WHO_AM_I: require("../assets/img/who-am-i.png").default,
  JOIN_LECTURE: require("../assets/img/join-lecture.png").default,
  STATS_PREVIEW: require("../assets/img/stats-preview.png").default,
  PRESENTATION_VIEW: require("../assets/img/presentation-view.png").default,
  TEACHER_REMOTE: require("../assets/img/teacher-remote.png").default,
  NO_PRESENTATION: require("../assets/img/no-presentation.png"),
};

export default Images;
