import { Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import SendInputMode from "../../../constants/SendInputMode";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import AnswerType from "../../../interfaces/answer/AnswerType";
import Colors from "../../../utils/Colors";
import { getTitleQAD, makeRestrictions } from "../../../utils/Utils";
import DiscussionButton from "../button/DiscussionButton";
import DislikeButton from "../button/DislikeButton";
import LikeButton from "../button/LikeButton";
import DialogHistory from "../dialog-history/DialogHistory";
import DiscussionDialog from "../discussion-dialog/DiscussionDialog";
import DividerLine from "../divider-line/DividerLine";
import FlagComponent from "../flag-component/FlagComponent";
import EditIcon from "../icons/EditIcon";
import MoreLessText from "../more-less-text/MoreLessText";
import MultilineQuestionAnswer from "../multiline-question-answer/MultilineQuestionAnswer";
import PadlockComponent from "../padlock-component/PadlockComponent";
import RemovedFromClass from "../removed-from-class/RemovedFromClass";
import RiseUpComponent from "../rise-up-component/RiseUpComponent";
import AnswerCardService, { AnswerCardServiceExport } from "./AnswerCardService";

interface AnswerCardType {
  answer: AnswerType;
  callbackRefresh: () => void;
  showInlineButtons?: boolean;
  showEditable?: boolean;
  questionId: number;
}

const AnswerCard = ({ answer, callbackRefresh, showInlineButtons = false, showEditable, questionId }: AnswerCardType) => {
  const {
    id,
    text,
    createdAt,
    pseudonym,
    lectureNum,
    numDownVotes,
    numUpVotes,
    flagged,
    hasRiseUp,
    selfPosted,
    selfFlagged,
    respondable,
    discussionCount,
    discussionNotifCount,
    removedFromClass,
    notificationId,
    editable,
    teacherAnswer,
    hasHistory,
  } = answer;

  const {
    voteAnswer,
    toggleFlag,
    riseUpAnswer,
    handleOpenDiscussion,
    handleCloseDiscussion,
    handleEditAnswer,
    handleEditIcon,
    discussionDialogState,
    readNotification,
    editAnswer,
    showEditField,
    openHistoryDialog,
    setOpenHistoryDialog,
  }: AnswerCardServiceExport = AnswerCardService({
    id,
    questionId,
    text,
    selfPosted,
    lectureNum,
    callbackRefresh,
  });

  const { isMobile } = useQueryMedia();

  const actionButtons = (
    <>
      <Grid sx={{ mb: 1 }}>
        <LikeButton onClick={() => voteAnswer(1)} disabled={selfPosted || !respondable} label={numUpVotes} />
        <DislikeButton onClick={() => voteAnswer(-1)} disabled={selfPosted || !respondable} label={numDownVotes} />
      </Grid>
      <DiscussionButton onClick={() => handleOpenDiscussion(answer)} label={discussionCount} hasNotification={Boolean(discussionNotifCount)} />
    </>
  );

  return (
    <div className="answer-card">
      <Grid container padding={1} className={`answer-card-container ${notificationId && "answer-card-container-not-read"}`} onClick={() => notificationId && readNotification(notificationId)}>
        <Grid container justifyContent="center" alignItems="start">
          <Grid
            container
            item
            flexDirection="column"
            xs={isMobile || showInlineButtons ? 12 : 9}
            sm={isMobile || showInlineButtons ? 12 : 9}
            md={isMobile || showInlineButtons ? 12 : 9}
            lg={isMobile || showInlineButtons ? 12 : 9.5}
            xl={isMobile || showInlineButtons ? 12 : 10}
          >
            <Grid item xs={12}>
              <>
                <Grid display="flex" alignItems="center" mb="4px">
                  <Typography color={Colors.BLUE_DIANNE} fontSize="10px" variant="caption">
                    {getTitleQAD(pseudonym, teacherAnswer, createdAt)}
                  </Typography>
                  {showEditable && editable && selfPosted && (
                    <Grid item style={{ marginLeft: 10, cursor: "pointer" }} onClick={handleEditIcon}>
                      <EditIcon width="12px" height="12px" fillColor={Colors.BLUE_DIANNE} />
                    </Grid>
                  )}
                  {removedFromClass && <RemovedFromClass />}
                  <PadlockComponent respondable={respondable} color={Colors.BLUE_DIANNE} />
                  {!selfPosted && !teacherAnswer && <FlagComponent color={Colors.BLUE_DIANNE} disabled={!respondable} selfFlagged={selfFlagged} handleToggleFlag={toggleFlag} />}
                  {!selfPosted && flagged && !hasRiseUp && <RiseUpComponent type={SendInputMode.A} disabled={!respondable} handleRiseUp={riseUpAnswer} hasRiseUp={true} />}
                </Grid>
                <DividerLine color={Colors.BLUE_DIANNE} />
              </>
            </Grid>
            {showEditField ? (
              <Grid container item justifyContent="center" mt="4px">
                <MultilineQuestionAnswer
                  placeholder="Edit a answer"
                  onSubmit={handleEditAnswer}
                  initValue={editAnswer}
                  chars={makeRestrictions(SendInputMode.A).chars}
                  words={makeRestrictions(SendInputMode.A).words}
                />
              </Grid>
            ) : (
              <>
                <MoreLessText text={text} />
                {hasHistory && (
                  <>
                    <Button className="answer-card-history" variant="text" sx={{ color: Colors.BLUE_DIANNE }} onClick={() => setOpenHistoryDialog(true)}>
                      [Edited]
                    </Button>
                    <DialogHistory questionId={answer.questionId} answerId={id} lectureNum={lectureNum} open={openHistoryDialog} type={SendInputMode.A} onClose={() => setOpenHistoryDialog(false)} />
                  </>
                )}
              </>
            )}
          </Grid>
          {(isMobile || showInlineButtons) && (
            <Grid item xs={12} textAlign="left" display="flex">
              {actionButtons}
            </Grid>
          )}

          {!isMobile && !showInlineButtons && (
            <Grid item xs={3} sm={3} md={3} lg={2.5} xl={2} textAlign="right">
              {actionButtons}
            </Grid>
          )}
        </Grid>
      </Grid>
      <DiscussionDialog state={discussionDialogState} handleClose={handleCloseDiscussion} refreshData={callbackRefresh} />
    </div>
  );
};

export default AnswerCard;
