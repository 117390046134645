import { Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import UserRole from "../../../../utils/UserRole";
import ButtonCustom from "../../../shared/button-custom/ButtonCustom";
import OutlinedInputCustom from "../../../shared/outlined-input-custom/OutlinedInputCustom";
import SwitchIOS from "../../../shared/switch-ios/SwitchIOS";
import SettingsService, { ISettingsService } from "./SettingsService";
import { INIT_VALUES, validationSchema } from "./config";

export default function Settings() {
  const { handleOnSubmit, handleToogleModerator, isModerator }: ISettingsService = SettingsService();
  const { isTeacher } = UserRole();
  return (
    <Formik initialValues={INIT_VALUES} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {({ handleBlur, values, touched, errors, handleChange, isValid, dirty }) => (
        <Form>
          <Grid container item xs={12} className="ac-settings" justifyContent="center" rowSpacing={2}>
            {/* <Grid item xs={12} textAlign="center" pt={1}>
              <Typography variant="h6">My Settings</Typography>
            </Grid> */}
            {isTeacher && (
              <>
                <Grid item xs={12} textAlign="center">
                  <Typography sx={{ fontSize: "1.1rem" }}>Are you a moderator?</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "0px !important" }}>
                  <Typography variant="subtitle2" className="class-status-text">
                    No
                  </Typography>
                  <SwitchIOS value={isModerator} onChange={handleToogleModerator} />
                  <Typography variant="subtitle2" className="class-status-text">
                    Yes
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} textAlign="center">
              <Typography sx={{ fontSize: "1.1rem" }}>Change Password</Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center" pb={2}>
              <Typography variant="body1">The new password should have at least 8 characters including at least one lower and uppercase letter, and at least one number.</Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center" alignItems="flex-start">
              <OutlinedInputCustom
                placeholder="Current password"
                name="password"
                value={values.password}
                onChange={handleChange}
                aria-describedby="outlined-id-helper-text"
                error={touched.password && Boolean(errors.password)}
                onBlur={handleBlur}
                type="password"
                fullWidth
                className="ac-text-field"
              />
              <div className="error-text">{touched.password && Boolean(errors.password) && <label>{errors.password}</label>}</div>
              <OutlinedInputCustom
                placeholder="New password"
                name="newPassword"
                value={values.newPassword}
                onChange={handleChange}
                aria-describedby="outlined-id-helper-text"
                error={touched.newPassword && Boolean(errors.newPassword)}
                onBlur={handleBlur}
                type="password"
                fullWidth
                className="ac-text-field"
              />
              <div className="error-text">{touched.newPassword && errors.newPassword && <label>{errors.newPassword}</label>}</div>
              <OutlinedInputCustom
                placeholder="Confirm new password"
                name="newPasswordConfirm"
                value={values.newPasswordConfirm}
                onChange={handleChange}
                aria-describedby="outlined-id-helper-text"
                error={touched.newPasswordConfirm && Boolean(errors.newPasswordConfirm)}
                onBlur={handleBlur}
                type="password"
                fullWidth
                className="ac-text-field"
              />
              <div className="error-text">{touched.newPasswordConfirm && errors.newPasswordConfirm && <label>{errors.newPasswordConfirm}</label>}</div>
            </Grid>
            <ButtonCustom disabled={!(isValid && dirty)} type="submit" gradient="dark-purple" label="Change" whiteBorder fontWeight="500" />
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
