import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { IconButton, Menu, SelectProps, styled, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import Colors from "../../../utils/Colors";

interface CustomSelectPropsNew extends SelectProps {
  componentColor?: string;
}

interface CustomSelectProps {
  title?: string;
  options: any;
  value: any;
  handleChange: any;
  componentColor?: string;
}

const arrayNotForwardProp: Array<string> = ["componentColor"];
const CustomSelectInput = styled(TextField, {
  shouldForwardProp: (prop) => !arrayNotForwardProp.includes(prop.toString()),
})<CustomSelectPropsNew>(({ componentColor = Colors.FILTER_BLUE }) => ({
  "& .MuiInputBase-input": {
    color: componentColor,
    backgroundColor: "transparent",
    fontSize: "10px",
    fontFamily: "Montserrat",
  },
  "& .MuiInputLabel-root ": {
    color: componentColor,
  },
  "& .MuiOutlinedInput-root ": {
    textAlign: "left",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: componentColor,
  },
  "& .MuiOutlinedInput-root": {
    height: "31.4px",
    "& fieldset": {
      borderColor: componentColor,
    },
    "&:hover fieldset": {
      borderColor: componentColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: componentColor,
    },
  },
  "& .MuiSelect-icon": {
    color: componentColor,
  },
}));

const WIDTH = 150;

export default function SelectCustom({ title, options, value, handleChange, componentColor = Colors.FILTER_BLUE }: CustomSelectProps) {
  const { isMobile } = useQueryMedia();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const custonSelectInputStyle = {
    "& label": { fontSize: "0.8rem" },
    width: WIDTH,
  };

  return !isMobile ? (
    <CustomSelectInput sx={custonSelectInputStyle} id="simple-select" select label={title} value={value} onChange={handleChange}>
      {options.map(({ id, label }: any) => (
        <MenuItem sx={{ width: WIDTH, fontSize: "0.75rem" }} key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </CustomSelectInput>
  ) : (
    <div>
      <IconButton id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        <FilterListOutlinedIcon style={{ color: componentColor }} fontSize="large" />
      </IconButton>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ "aria-labelledby": "basic-button" }}>
        {options.map(({ id, label }: any) => (
          <MenuItem
            sx={{ width: WIDTH, fontSize: "0.75rem" }}
            key={id}
            value={id}
            onClick={(e) => {
              handleChange(e);
              handleClose();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
