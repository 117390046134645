import { useModal } from "mui-modal-provider";
import { useEffect, useState } from "react";
import { wsEvents } from "../../../../../constants";
import { useAppContext } from "../../../../../context/AuthContext";
import { ClassIdGeneratorSocket } from "../../../../../controller";
import MessageType from "../../../../../utils/MessageType";
import AutocreateMeetingDialog from "../../add-lecture/AutocreateMeetingDialog";

interface ClassIdServiceType {
  handleToggleState: any;
  codeValidUntil: any;
  currentClassCode: any;
}

export default function ClassIdService({ lectureNum, getLectures }: any): ClassIdServiceType {
  const { selectedCourse, showMessage } = useAppContext();
  const courseId = selectedCourse.id;
  const [codeValidUntil, setCodeValidUntil] = useState<number>(0);
  const [currentClassCode, setCurrentClassCode] = useState<any>("");
  const { showModal } = useModal();

  useEffect(() => {
    if (lectureNum) {
      connectClassCodes();
      checkCurrentClassId();
    }

    return () => clearClassId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureNum]);

  useEffect(() => {
    if (!codeValidUntil) return;

    const interval = setInterval(() => {
      setCodeValidUntil(codeValidUntil - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [codeValidUntil]);

  const connectClassCodes = () => {
    ClassIdGeneratorSocket.connect({ query: { courseId, lectureNum } });

    ClassIdGeneratorSocket.on(wsEvents.recv.ERROR_ACCESS_DENIED, handleAccessDenied);
    ClassIdGeneratorSocket.on(wsEvents.recv.NEW_CLASS_ID, handleNewClassId);
    ClassIdGeneratorSocket.on(wsEvents.recv.ACCESS_GRANTED, handleAccessGranted);
    ClassIdGeneratorSocket.on(wsEvents.recv.CURRENT_CLASS_ID_RECEIVED, handleCurrentClassId);
    ClassIdGeneratorSocket.on(wsEvents.recv.ERROR_COURSE_BUSY, handleErrorCourseBusy);
  };

  const clearClassId = () => {
    ClassIdGeneratorSocket.off(wsEvents.recv.ERROR_ACCESS_DENIED, handleAccessDenied);
    ClassIdGeneratorSocket.off(wsEvents.recv.NEW_CLASS_ID, handleNewClassId);
    ClassIdGeneratorSocket.off(wsEvents.recv.ACCESS_GRANTED, handleAccessGranted);
    ClassIdGeneratorSocket.off(wsEvents.recv.CURRENT_CLASS_ID_RECEIVED, handleCurrentClassId);
    ClassIdGeneratorSocket.off(wsEvents.recv.ERROR_COURSE_BUSY, handleErrorCourseBusy);
    ClassIdGeneratorSocket.disconnect();
  };

  const handleAccessDenied = (...args: any) => {
    // TODO figure out how we want to handle this
    // add an error message somewhere?
    console.log("access denied", ...args);
  };

  const handleErrorCourseBusy = () => showMessage("Error course busy", MessageType.ERROR);

  const handleNewClassId = (val: any) => {
    console.log("Handling new class id...");
    const [code, validUntil] = val.split(" ");
    console.log(validUntil);
    setCodeValidUntil(validUntil);
    setCurrentClassCode(code);
  };

  const handleCurrentClassId = (val: any) => {
    console.log("Got current clas id:", val);
    if (val) {
      const { classId, ttl } = val;
      setCodeValidUntil(ttl);
      setCurrentClassCode(classId);
    }
  };

  // TODO relevant to the todo above
  const handleAccessGranted = (...args: any) => {
    console.log("ClassId access granted", ...args);
  };

  const handleToogleStateAction = () => {
    if (!currentClassCode) {
      ClassIdGeneratorSocket.emit(wsEvents.send.START_CLASS_ID_GENERATION);
    } else {
      setCurrentClassCode("");
      setCodeValidUntil(0);
      ClassIdGeneratorSocket.emit(wsEvents.send.STOP_CLASS_ID_GENERATION);
    }
  };

  const handleToggleState = () => {
    if (!lectureNum) {
      showModal(AutocreateMeetingDialog, { confirmAction: getLectures });
    } else {
      handleToogleStateAction();
    }
  };
  const checkCurrentClassId = () => {
    console.log("Checking if current class id is available ...");
    ClassIdGeneratorSocket.emit(wsEvents.send.GET_CURRENT_CLASS_ID); // makes sure to return current class id if its running
  };

  return { handleToggleState, codeValidUntil, currentClassCode };
}

export type { ClassIdServiceType };
