import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Document } from "react-pdf";
import { useAppContext } from "../../../../context/AuthContext";
import useLectureType, { UseLectureTypeExport } from "../../../../hooks/useLectureType";
import LectureNumParams from "../../../../utils/LectureNumParams";
import StatsCard from "../../../shared/stats-card/StatsCard";

interface PagingType {
  startPage: number;
  endPage: number;
}

export default function Stats() {
  const baseClass = "stats";
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const lectureNum = LectureNumParams();
  const { presentationBlob, noPresentation, slides }: UseLectureTypeExport = useLectureType({ lectureNum });
  const [numOfPages, setNumOfPages] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [pagesForDisplay, setPagesForDisplay] = useState<PagingType>({ startPage: 1, endPage: 12 });
  // const [hideAll, setHideAll] = useState<boolean>(true);

  useEffect(() => {
    showPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, numOfPages]);

  useEffect(() => {
    setNumOfPages(slides.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides]);

  const showPage = () => {
    const maxPages = Math.ceil(numOfPages / 12);
    if (page === 1) {
      if (numOfPages < 12) {
        setPagesForDisplay({ startPage: 1, endPage: numOfPages % 12 });
      } else {
        setPagesForDisplay({ startPage: 1, endPage: 12 });
      }
    } else if (maxPages === page) {
      setPagesForDisplay({ startPage: (page - 1) * 12 + 1, endPage: (page - 1) * 12 + (numOfPages % 12) });
    } else {
      setPagesForDisplay({ startPage: (page - 1) * 12 + 1, endPage: (page - 1) * 12 + 12 });
    }
  };

  const range = (start: number, end: number) => {
    let arr = [];
    while (start <= end) {
      arr.push(start++);
    }
    return arr;
  };

  const handleForward = () => {
    if (page < Math.ceil(numOfPages / 12)) setPage(page + 1);
  };

  const handleBackward = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <div className={`${baseClass}`}>
      <div>
        <Grid container ml="15px">
          <Grid item xs={4} />
          <Grid item xs={4} sx={{ paddingBottom: 2 }} className={`${baseClass}__subtext`}>
            Statistics
          </Grid>
          <Grid item xs={4} />
          {/* <Grid container item xs={4} justifyContent="flex-end" alignItems="center">
            <Typography variant="subtitle2" color={Colors.WHITE} sx={{ fontSize: "10px", alignSelf: "center", width: "52px" }}>
              Show/Hide Statistics
            </Typography>
            <SwitchIOS value={hideAll} onChange={() => setHideAll(!hideAll)} activeColor={Colors.RED_SOLID} deactiveColor={Colors.GREEN_SOLID} />
          </Grid> */}
        </Grid>
        {presentationBlob ? (
          <Document file={presentationBlob} onLoadSuccess={(data: any) => setNumOfPages(data?._pdfInfo?.numPages)}>
            <Grid container rowSpacing={2} columnSpacing={2} pl={3}>
              {pagesForDisplay &&
                range(pagesForDisplay.startPage, pagesForDisplay.endPage).map((e: any) => (
                  <Grid key={e} item xs={6} sm="auto" md="auto" lg="auto" xl="auto">
                    <StatsCard
                      pageNumber={e}
                      courseId={courseId}
                      lectureNum={lectureNum}
                      // showAll={!hideAll}
                      showAll={true}
                      numOfPages={numOfPages}
                    />
                  </Grid>
                ))}
            </Grid>
          </Document>
        ) : noPresentation && slides.length > 0 ? (
          <Grid container rowSpacing={2} columnSpacing={2}>
            {range(slides[0].slideNumber, slides[slides.length - 1].slideNumber).map((e: any) => (
              <Grid key={e} item xs={6} sm="auto" md="auto" lg="auto" xl="auto">
                <StatsCard
                  noPresentation
                  pageNumber={e}
                  courseId={courseId}
                  lectureNum={lectureNum}
                  // showAll={!hideAll}
                  showAll={true}
                  numOfPages={numOfPages}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No statistics were collected for this meeting.</Typography>
        )}
      </div>
      {numOfPages > 12 && (
        <Grid container width={"100%"} justifyContent="center" mb={20} ml={2}>
          <Button onClick={handleBackward}>{"<"}</Button>
          <Button> {page} </Button>
          <Button onClick={handleForward}>{">"}</Button>
        </Grid>
      )}
    </div>
  );
}
