import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().required("Email is required.").email("Email is not valid."),
  university: yup.string().required("Organization is required."),
  password: yup.string().required("Password is required."),
  passwordConfirm: yup
    .mixed()
    .test("match", "Password do not match", function (password) {
      return password === this.parent.password;
    })
    .required("Confirm password is required"),
});

export const INIT_VALUES = { firstName: "", lastName: "", email: "", university: "", username: "", password: "", passwordConfirm: "" };
