import { mdiTuneVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { Grid, IconButton } from "@mui/material";
import SendInputMode from "../../../../constants/SendInputMode";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import UserRole from "../../../../utils/UserRole";
import { sortListByItem } from "../../../../utils/Utils";
import MultiselectCustom from "../../../shared/multiselect-custom/MultiselectCustom";
import { a11yProps, AntTab, AntTabs, TabPanel } from "../../../shared/my-tabs-custom/MyTabsCustom";
import SelectCustom from "../../../shared/select-custom/SelectCustom";
import SendInput from "../../../shared/send-input/SendInput";
import { optionsSortBy } from "./config";
import MyQuestionsService, { MyQuestionsServiceType } from "./MyQuestionsService";
import MyQuestionTab from "./MyQuestionTab";

export default function MyQuestionTabs() {
  const {
    tabValue,
    handleTabChange,
    sortBy,
    handleSortBy,
    courses,
    lectures,
    handleCoursesChange,
    handleLecturesChange,
    selectedCourses,
    setSelectedCourses,
    selectedLectures,
    setSelectedLectures,
    callbackUpdateState,
    showFilter,
    toggleFilter,
    question,
    questions,
    handleSendQuestion,
    disabledInput,
    setQuestion,
  }: MyQuestionsServiceType = MyQuestionsService();
  const { isTeacher, isStudent } = UserRole();
  const { isMobile } = useQueryMedia();
  const { my, all, flagged } = questions;

  const createMultiselectRow = (
    <>
      <Grid container item xs={6} justifyContent="center">
        <MultiselectCustom
          title="Meetings"
          value={selectedLectures}
          handleChange={handleLecturesChange}
          options={lectures}
          setValue={setSelectedLectures}
          showAll={true}
          showNone={true}
          disabled={selectedCourses.length === 0}
        />
      </Grid>
      <Grid container item xs={6} justifyContent="center">
        {false && <MultiselectCustom title="Course" value={selectedCourses} handleChange={handleCoursesChange} options={courses} setValue={setSelectedCourses} showAll={true} showNone={true} />}{" "}
      </Grid>
    </>
  );

  const mobileHeight = () => {
    let height = isMobile ? window.screen.height - 205 : 590;
    let additionalHeight = 0;

    if (showFilter) {
      additionalHeight += isMobile ? 30 : 0;
    }
    if (selectedLectures.length === 1) {
      additionalHeight += isMobile ? 70 : 80;
    }

    return `${height - additionalHeight}px`;
  };

  const renderTabsWidth = isTeacher ? 50 : 33.33;
  const height =
    document?.getElementsByClassName(`${isTeacher ? "teacher" : "student"}-sidebar`).length > 0
      ? document?.getElementsByClassName(`${isTeacher ? "teacher" : "student"}-sidebar`)[0].clientHeight
      : null;

  return (
    <Grid container justifyContent="center" alignItems="center" className="custom-tabs-scrollbar" sx={{ pt: 1 }} minHeight={isMobile ? "undefined" : height ?? 550}>
      {isMobile && (
        <Grid container justifyContent="center" alignItems="center">
          <div className="student-layout__subtext">Questions</div>
        </Grid>
      )}
      <Grid sx={{ display: isMobile && showFilter ? "flex" : "none" }} container item xs={12} justifyContent="center">
        <Grid container item xs={12} justifyContent="center">
          <MultiselectCustom
            title="Meetings"
            value={selectedLectures}
            handleChange={handleLecturesChange}
            options={lectures}
            setValue={setSelectedLectures}
            showAll={true}
            showNone={true}
            disabled={selectedCourses.length === 0}
          />
        </Grid>
        {false && (
          <Grid container item xs={6} justifyContent="center">
            <MultiselectCustom title="Course" value={selectedCourses} handleChange={handleCoursesChange} options={courses} setValue={setSelectedCourses} showAll={true} showNone={true} />
          </Grid>
        )}
      </Grid>
      <Grid sx={{ display: isMobile ? "flex" : "none" }} item xs={2} sm={2} md={4} lg={4} xl={4} justifyContent="center">
        <IconButton color="info" onClick={toggleFilter}>
          <Icon path={mdiTuneVariant} size={1} />
        </IconButton>
      </Grid>
      <Grid sx={{ display: !isMobile ? "flex" : "none" }} container item xs={3} sm={3} md={4} lg={4} xl={4} justifyContent="flex-start">
        {createMultiselectRow}
      </Grid>
      <Grid item xs={8} sm={6} md={4} lg={4} xl={4}>
        <AntTabs value={tabValue} onChange={handleTabChange} aria-label="ant tabs" TabIndicatorProps={{ style: { display: "none" } }}>
          <AntTab label="My" {...a11yProps(EnumTabs.Q_MY)} width={renderTabsWidth} />
          <AntTab label="All" {...a11yProps(EnumTabs.Q_ALL)} width={renderTabsWidth} />
          {isStudent && <AntTab label="Doghouse " {...a11yProps(EnumTabs.Q_FLAG)} width={renderTabsWidth} />}
        </AntTabs>
      </Grid>
      <Grid container item xs={2} sm={2} md={4} lg={4} xl={4} justifyContent="flex-end" alignItems="center">
        <SelectCustom title="Sort by" value={sortBy} handleChange={handleSortBy} options={optionsSortBy} />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, height: mobileHeight(), overflowY: "auto" }}>
        <TabPanel value={tabValue} index={EnumTabs.Q_MY}>
          <MyQuestionTab questions={sortListByItem(my, sortBy)} callbackRefresh={callbackUpdateState} selectedCourses={selectedCourses} selectedLectures={selectedLectures} tabValue={tabValue} />
        </TabPanel>
        <TabPanel value={tabValue} index={EnumTabs.Q_ALL}>
          <MyQuestionTab questions={sortListByItem(all, sortBy)} callbackRefresh={callbackUpdateState} selectedCourses={selectedCourses} selectedLectures={selectedLectures} tabValue={tabValue} />
        </TabPanel>
        <TabPanel value={tabValue} index={EnumTabs.Q_FLAG}>
          <MyQuestionTab questions={sortListByItem(flagged, sortBy)} callbackRefresh={callbackUpdateState} selectedCourses={selectedCourses} selectedLectures={selectedLectures} tabValue={tabValue} />
        </TabPanel>
      </Grid>
      {selectedLectures.length === 1 && <SendInput value={question} setText={setQuestion} handleSend={handleSendQuestion} disabled={disabledInput} isMobile={isMobile} type={SendInputMode.Q} />}
    </Grid>
  );
}
