import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import Colors from "../../../utils/Colors";
import { TIME_FORMAT_2 } from "../../../utils/Utils";

interface IDialogMeetingCreateSuccess {
  meeting: any;
}

const DialogMeetingCreateSuccess = ({ meeting }: IDialogMeetingCreateSuccess) => {
  const { destroyModal, state } = useModal();
  const modalId = Object.keys(state)[0];
  const closeAction = () => destroyModal(modalId);
  return (
    <Dialog open={true} fullWidth sx={{ textAlign: "center" }}>
      <DialogContent color={Colors.BLACK}>
        <div>We created the meeting with following details:</div>
        <div>Title: {meeting?.name}</div>
        <div>Start time: {moment(meeting?.startDate).format(TIME_FORMAT_2)}</div>
        <div>End time: {moment(meeting?.endTime).format(TIME_FORMAT_2)}</div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="v-btn ac-color-white btn-send" sx={{ color: Colors.BLACK }} onClick={closeAction}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogMeetingCreateSuccess;
