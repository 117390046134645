import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import i18n from "i18n-js";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { ACCESS_TOKEN, SELECTED_COURSE, SELECTED_LECTURE, TEACHER_ADMIN, USER_ROLE } from "../constants/auth";
import { AppContext } from "../context/AuthContext";
import { Course } from "../controller";
import ENGLISH from "./../lang/Labels_en";
import Colors from "./Colors";
import EnumLanguage from "./enum/EnumLanguange";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

i18n.fallbacks = true;
i18n.translations = { ENGLISH };

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      main: Colors.AZURE_RADIANCE,
      contrastText: Colors.WHITE,
    },
    secondary: {
      main: Colors.NAVY_SOLID,
    },
    error: {
      main: "#EC1C24",
    },
    info: {
      main: Colors.FILTER_BLUE,
    },
    success: {
      main: "#24B300",
    },
    warning: {
      main: "#FFC107",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Some CSS
          textTransform: "none",
          fontSize: "1rem",
          borderRadius: "30px",
          color: "white",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
  },
});

export default function Store(props: any) {
  const [accessToken, setAccessToken] = useState(localStorage.getItem(ACCESS_TOKEN) || "");
  const [locale, setLocale] = useState(EnumLanguage.ENGLISH);
  const [course, setCourse] = useState<any>(localStorage.getItem(SELECTED_COURSE) ? JSON.parse(localStorage.getItem(SELECTED_COURSE) ?? "") : undefined);
  const [lecture, setLecture] = useState<any>(localStorage.getItem(SELECTED_LECTURE) ? JSON.parse(localStorage.getItem(SELECTED_LECTURE) ?? "") : undefined);
  const [isTeacherAdmin, setIsTeacherAdmin] = useState<boolean>(!!localStorage.getItem(TEACHER_ADMIN) || false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (accessToken) {
      Course.getAllCourses()
        .then(({ data }: any) => {
          if (data?.message) {
            const course = data.data[0];
            if (course) {
              updateSelectedCourse(course);
            } else {
              console.log("User dont have course.");
            }
          } else {
            console.error(data);
          }
        })
        .catch((err: any) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  const showMessage = (text: any, type: any) => {
    const msg = !Boolean(text) || !Boolean(Object.keys(text).length) ? "Client:Server error" : text;
    const key = enqueueSnackbar(msg, {
      onClick: () => closeSnackbar(key),
      preventDuplicate: true,
      variant: type,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  const updateAccessToken = (data: string) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(ACCESS_TOKEN, jsonData);
    setAccessToken(jsonData);
  };

  const updateIsTeacherAdmin = (data: boolean) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(TEACHER_ADMIN, jsonData);
    setIsTeacherAdmin(!!jsonData);
  };

  const updateSelectedCourse = (data: string) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(SELECTED_COURSE, jsonData);
    setCourse(data);
    updateSelectedLecture("");
  };

  const updateSelectedLecture = (data: string) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(SELECTED_LECTURE, jsonData);
    setLecture(data);
  };

  const updateRole = (data: string) => {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(USER_ROLE, jsonData);
  };

  const deleteAccessToken = () => {
    setAccessToken("");
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(SELECTED_COURSE);
    setCourse(undefined);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContext.Provider
          value={{
            role: localStorage.getItem(USER_ROLE) ? (JSON.parse(localStorage.getItem(USER_ROLE)!) as string) : "",
            updateRole,
            selectedCourse: course,
            updateSelectedCourse: updateSelectedCourse,
            selectedLecture: lecture,
            updateSelectedLecture: updateSelectedLecture,
            accessToken: accessToken ? (JSON.parse(accessToken) as string) : undefined,
            updateAccessToken,
            deleteAccessToken,
            showMessage,
            t: (scope: any, options: any) => i18n.t(scope, { locale, ...options }),
            locale: locale,
            setLocale,
            isTeacherAdmin,
            updateIsTeacherAdmin,
          }}
        >
          {props.children}
        </AppContext.Provider>
      </ThemeProvider>
    </>
  );
}
