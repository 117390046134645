import EnumSortItem from "../../../../utils/enum/EnumSortItem";

const optionsSortBy = [
  { id: EnumSortItem.TIME, label: "Time" },
  { id: EnumSortItem.VOTE, label: "Votes" },
  { id: EnumSortItem.NUM_OF_ANSWERS, label: "Number of answers" },
  { id: EnumSortItem.FLAG, label: "Flag" },
  { id: EnumSortItem.LECTURE_NUM, label: "Meeting number" },
];

export { optionsSortBy };
