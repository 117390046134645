import { Box, Button, Grid } from "@mui/material";
import Colors from "../../../../../utils/Colors";
import DialogYesNo from "../../../../shared/dialog-yes-no/DialogYesNo";
import OutlinedInputCustom from "../../../../shared/outlined-input-custom/OutlinedInputCustom";
import ClassWaitingRoomService, { ClassWaitingRoomServiceType } from "./ClassWaitingRoomService";

interface ClassWaitingRoomPropsType {
  lectureNum?: number;
  sideDisplay?: boolean;
  newConnectionWarning?: boolean;
  closeWarningStartLecture?: () => void;
}

export default function ClassWaitingRoom({ lectureNum, sideDisplay, newConnectionWarning, closeWarningStartLecture }: ClassWaitingRoomPropsType) {
  const {
    stateWaitingRoom,
    handleAdmitAccountsClick,
    handleRejectAccountsClick,
    handleAdmitPseudonymsClick,
    handleRejectPseudonymsClick,
    handleOpenResetDialog,
    showResetDialog,
    resetConfirmActionFunc,
    showStartLectureDialog,
    setShowStartLectureDialog,
    setShowResetDialog,
    warningConfirmActionFunc,
    warningRejectActionFunc,
    showWarningDialog,
    setShowWarningDialog,
  }: ClassWaitingRoomServiceType = ClassWaitingRoomService({ lectureNum, newConnectionWarning, closeWarningStartLecture });

  const { newConnected, notRegisteredConnected, registeredConnected } = stateWaitingRoom;
  const upperCase = { typography: "subtitle2", textTransform: "uppercase", ...(sideDisplay && { fontSize: "12px" }) };
  return (
    <div className="class-status">
      <Grid container>
        {!sideDisplay && (
          <>
            <Grid item xs={12} style={{ padding: "0px !important" }}>
              <Box sx={upperCase} className="class-status-text">
                Request for new:
              </Box>
            </Grid>
            <Grid item xs={12}>
              <hr className="class-status-card-divider-small" />
            </Grid>
          </>
        )}
        <Grid item xs={sideDisplay ? 12 : 6}>
          <Grid className="container-section" container columnSpacing={3} rowSpacing={sideDisplay ? 0 : 1} padding={sideDisplay ? 0 : 2}>
            <Grid item xs={12}>
              <Box sx={upperCase} className="class-status-text">
                Accounts
              </Box>
            </Grid>
            <Grid item xs={12}>
              <OutlinedInputCustom
                className="text-field"
                inputProps={{ style: { textAlign: "center", ...(sideDisplay && { padding: "4px" }) } }}
                readOnly={true}
                value={newConnected + notRegisteredConnected}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Box sx={{ ...upperCase, mt: 1 }} className="class-status-text">
                Admit?
              </Box>
            </Grid>
            <Grid item xs={6} md={6} sm={6} className="container-section-action">
              <Button
                onClick={handleAdmitAccountsClick}
                variant="contained"
                style={{ backgroundColor: Colors.GREEN_SOLID, color: Colors.WHITE, ...(sideDisplay && { padding: "1px" }) }}
                className="button-custom"
                disabled={!lectureNum}
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={6} md={6} sm={6} className="container-section-action">
              <Button
                onClick={handleRejectAccountsClick}
                variant="contained"
                style={{ backgroundColor: Colors.RED_LIGHT, color: Colors.WHITE, ...(sideDisplay && { padding: "1px" }) }}
                className="button-custom"
                disabled={!lectureNum}
              >
                No
              </Button>
            </Grid>
            {!sideDisplay && (
              <Grid item xs={12} md={12} sm={12} className="container-section-action">
                <Button
                  onClick={() => handleOpenResetDialog("account")}
                  variant="contained"
                  style={{ backgroundColor: Colors.YELLOW_REACT, color: Colors.BLACK }}
                  className="button-custom"
                  disabled={!lectureNum}
                >
                  Reset
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={sideDisplay ? 12 : 6} className="container-section-action">
          <Grid className="container-section" container columnSpacing={3} rowSpacing={sideDisplay ? 0 : 1} padding={sideDisplay ? 0 : 2}>
            <Grid item xs={12}>
              <Box sx={upperCase} className="class-status-text">
                Pseudonyms
              </Box>
            </Grid>
            <Grid item xs={12}>
              <OutlinedInputCustom className="text-field" inputProps={{ style: { textAlign: "center", ...(sideDisplay && { padding: "4px" }) } }} readOnly={true} value={registeredConnected} />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ ...upperCase, mt: 1 }} className="class-status-text">
                Admit?
              </Box>
            </Grid>
            <Grid item xs={6} md={6} className="container-section-action">
              <Button
                onClick={handleAdmitPseudonymsClick}
                variant="contained"
                style={{ backgroundColor: Colors.GREEN_SOLID, color: Colors.WHITE, ...(sideDisplay && { padding: "1px" }) }}
                className="button-custom"
                disabled={!lectureNum}
              >
                Yes
              </Button>
            </Grid>
            <Grid item xs={6} md={6} className="container-section-action">
              <Button
                onClick={handleRejectPseudonymsClick}
                variant="contained"
                style={{ backgroundColor: Colors.RED_LIGHT, color: Colors.WHITE, ...(sideDisplay && { padding: "1px" }) }}
                className="button-custom"
                disabled={!lectureNum}
              >
                No
              </Button>
            </Grid>
            {!sideDisplay && (
              <Grid item xs={12} md={12} className="container-section-action">
                <Button
                  onClick={() => handleOpenResetDialog("pseudonyms")}
                  variant="contained"
                  style={{ backgroundColor: Colors.YELLOW_REACT, color: Colors.BLACK }}
                  className="button-custom"
                  disabled={!lectureNum}
                >
                  Reset
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <DialogYesNo
          // dialogData={newConnectionWarning && showWarningDialog.show}
          dialogData={showWarningDialog}
          setDialogData={setShowWarningDialog}
          confirmAction={warningConfirmActionFunc}
          rejectAction={warningRejectActionFunc}
        />
        <DialogYesNo dialogData={showStartLectureDialog} setDialogData={setShowStartLectureDialog} confirmAction={undefined} />
        <DialogYesNo dialogData={showResetDialog} setDialogData={setShowResetDialog} confirmAction={resetConfirmActionFunc} />
      </Grid>
    </div>
  );
}
