import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT_STYLE, isLectureFuture, isLectureLive, parseStartEndTime } from "../../../../utils/Utils";
import { AddButton } from "../../../shared/components/styledComponents";
import GridCard from "../../../shared/grid-card/GridCard";
import AddIcon from "../../../shared/icons/AddIcon";
import MenuAnon from "../../../shared/menu/MenuAnon";
import MyLecturesService, { MyLecturesServiceType } from "./MyLecturesService";

export default function MyLectures() {
  const history = useHistory();
  const { updateSelectedLecture } = useAppContext();
  const { lectures, openMenuLecture, handleOpenMenu, handleCloseMenu, anchorEl, setAnchorEl }: MyLecturesServiceType = MyLecturesService();

  const endLecture = lectures.filter((l: LectureDto) => !isLectureLive(l));
  const liveLecture = lectures.filter((l: LectureDto) => isLectureLive(l));

  const sortLectures = (lectureArray: LectureDto[]) => {
    return lectureArray.sort((a: LectureDto, b: LectureDto) => {
      return moment(a.startTime).valueOf() - moment(b.startTime).valueOf();
    });
  };

  return (
    <div className="my-lectures">
      <div className="lecture-card__subtext">{lectures?.length ? "My Meetings" : "There is no meetings at this time.  Please create a new meeting."}</div>
      <Box sx={{ pb: 2 }}>
        <AddButton startIcon={<AddIcon />} onClick={() => history.push(Routes.TEACHER_MY_LECTURES_ADD)}>
          Add a New Meeting
        </AddButton>
      </Box>
      <Grid container justifyContent={{ xs: "center", sm: "center", md: "flex-start", lg: "flex-start", xl: "flex-start" }} alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
        {sortLectures([...endLecture, ...liveLecture]).map(({ id, name, startTime, endTime, lectureNum }: LectureDto, index: number) => {
          const isLive = isLectureLive({ startTime, endTime });
          const isFuture = isLectureFuture({ startTime, endTime });
          return (
            <Grid key={id} item sx={{ color: `${Colors.RED_SOLID} !important` }} style={{ backgroundColor: "green !important" }}>
              <GridCard
                isLive={isLive}
                isFuture={isFuture}
                position={index + 1}
                title={startTime ? moment(startTime).format(DATE_FORMAT_STYLE) : ""}
                showPointer={isLive}
                cardAction={() => isLive && history.push(Routes.TEACHER_CLASS_STATUS)}
                subtitle={parseStartEndTime(startTime, endTime)}
                headerAction={(event: any) => {
                  event.stopPropagation();
                  setAnchorEl(event.currentTarget);
                  handleOpenMenu(id);
                }}
                showDots={true}
                name={name}
                active={false}
                color={isFuture ? Colors.LECTURE_FUTURE : isLive ? Colors.LECTURE_LIVE : Colors.LECTURE_LIVE}
              />
              <MenuAnon
                anchorEl={anchorEl}
                id={`menu-${name}`}
                open={openMenuLecture === id}
                handleClose={handleCloseMenu}
                items={[
                  {
                    label: "Live questions",
                    action: () => history.push(Routes.TEACHER_QUESTIONS.replace(":lectureNum", String(lectureNum))),
                  },
                  {
                    label: "Statistics",
                    action: () => history.push(Routes.LECTURE_STATS.replace(":lectureNum", String(lectureNum))),
                  },
                  {
                    label: "My questions",
                    action: () =>
                      history.push({
                        pathname: Routes.TEACHER_MY_QUESTIONS,
                        state: { initLectureId: id },
                      }),
                  },
                  {
                    label: "My answers",
                    action: () =>
                      history.push({
                        pathname: Routes.TEACHER_MY_ANSWERS,
                        state: { initLectureId: id },
                      }),
                  },
                  {
                    label: "Edit",
                    action: () => {
                      updateSelectedLecture({ id, name, lectureNum });
                      history.push(`${Routes.TEACHER_MY_LECTURES}/edit/${lectureNum}`);
                    },
                  },
                ]}
              />
            </Grid>
          );
        })}
        {lectures && lectures?.length / 2 !== 0 && <Grid container className="lecture-card__fake-card" item></Grid>}
      </Grid>
    </div>
  );
}
