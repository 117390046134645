import { Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import packageJson from "../../../../package.json";
import Routers from "../../../utils/Routes";
import ButtonCustom from "../../shared/button-custom/ButtonCustom";
import ArrowIcon from "../../shared/icons/ArrowIcon";
import LetterA from "../../shared/icons/LetterA";
import ProffessorIcon from "../../shared/icons/ProffessorIcon";
import StudentIcon from "../../shared/icons/StudentIcon";

export default function About() {
  const history = useHistory();
  const [expand, setExpand] = useState<boolean>(false);
  return (
    <Grid container p={0} className="ac-about-page" justifyContent="center" alignItems="center" flexDirection="column">
      <LetterA />
      <Typography variant="h5" fontWeight="bold">
        Anonymous + Accountable
      </Typography>
      <Typography variant="h6">Meeting Interaction</Typography>
      <Typography variant="body1">where the power of arguments matters more than the power of individuals that make the arguments</Typography>

      <Grid container justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} py={2}>
        <Grid item xs={10} sm={8} md={3}>
          <ButtonCustom onClick={() => history.push(Routers.REGISTER_STUDENT)} gradient="green" textColor="black" label="Participant" fontWeight="600" startIcon={<StudentIcon />} />
        </Grid>
        <Grid item xs={10} sm={8} md={3}>
          <ButtonCustom onClick={() => history.push(Routers.TEACHER_LOGIN)} gradient="blue" label="Organizer" fontWeight="600" startIcon={<ProffessorIcon width="50px" height="30px" />} />
        </Grid>
      </Grid>

      <Grid container item justifyContent="center">
        <Typography variant="body2">
          This platform provides:
          <br />
          Real-time feedback during the meeting.
          <br />
          Space for open conversation without social and peer pressures.
        </Typography>
      </Grid>

      <Grid container justifyContent="center" textAlign="center" pt={1}>
        <Grid item xs={4.5} sm={4.5} md={4} lg={4} xl={4} alignSelf="center">
          <div className="ac-about-page__white-line" style={{ float: "right" }} />
        </Grid>
        <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
          <Typography variant="body1">Read more</Typography>
        </Grid>
        <Grid item xs={4.5} sm={4.5} md={4} lg={4} xl={4} alignSelf="center">
          <div className="ac-about-page__white-line" />
        </Grid>
      </Grid>

      <Grid container item justifyContent="center">
        <IconButton onClick={() => setExpand(!expand)}>
          <ArrowIcon rotate={expand ? 180 : 0} />
        </IconButton>
      </Grid>

      <Grid container justifyContent="center">
        <Grid item mb={3} sx={{ display: expand ? "flex" : "none" }} xs={10} sm={10} md={7} lg={7} xl={7}>
          <Typography variant="body2">
            Meetings should be informative and productive.
            <br />
            However, they are often boring and even meaningless.
            <br />
            The goal of the AnonMeeting platform is to make meetings interactive, efficient, and enjoyable.
            <br /> The platform provides a space where a presenter could get real-time feedback from the audience, and where everyone could freely express their opinions.
            <br />
            Furthermore, all discussions could continue long after a meeting is over.
          </Typography>
        </Grid>
      </Grid>
      <span style={{ fontSize: 10, color: "white", position: "absolute", right: "5px", bottom: "5px" }}>{`v${packageJson.version}`}</span>
    </Grid>
  );
}
