import { SelectChangeEvent } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { SlideInteractions } from "../../../../controller/SlideInteraction";
import EnumExpressionsNum from "../../../../utils/enum/EnumExpressionsNum";
import EnumSegment from "../../../../utils/enum/EnumSegment";
import MessageType from "../../../../utils/MessageType";
import { segmentList } from "./config";

interface coordsType {
  x: number;
  y: number;
}

interface IntervalType {
  intervalNum: number;
  startInSec: string;
  endInSec: string;
  expressions: Array<ExpressionType | undefined>;
}

interface ExpressionType {
  id: number;
  activatedAtSec: number;
  tag: string;
}

interface SlideInteractionsType {
  slideNum: string;
  startedAt: string;
  duritationSec: number;
  intervals: [IntervalType];
  speakerName: string;
}

interface SlideStatisticsServiceExport {
  convertSeconds: (seconds: number | undefined) => string;
  handleSortBy: (e: SelectChangeEvent) => any;
  segmentBy: number;
  slideNumber: number;
  handleBackward: () => any;
  handleForward: () => any;
  handleMouseMove: (e: any) => any;
  chartContainerRef: any;
  coords: coordsType;
  lineTime: number;
  combinedData: any;
  pickedData: any;
  maxExpression: number;
  setShowLegend: any;
  showLegend: boolean;
  maxValuePerExpression: any;
  percentagePerExpression: any;
  expressionData: SlideInteractionsType | undefined;
  graphWidth: number;
  noPresentation: boolean;
}

export default function SlideStatisticsService(): SlideStatisticsServiceExport {
  const { showMessage } = useAppContext();
  const chartContainerRef: any = useRef();
  const { courseId, lectureNum, pageNumber, numOfPages, noPresentation = false }: any = useLocation().state;
  const [segmentBy, setSegmenyBy] = useState<number>(segmentList[0].id);
  const [slideNumber, setSlideNumber] = useState<number>(pageNumber);
  const [coords, setCoords] = useState<coordsType>({ x: 0, y: 0 });
  const [lineTime, setLineTime] = useState<number>(0);
  const [combinedData, setCombinedData] = useState<any>([]);
  const [pickedData, setPickedData] = useState<any>();
  const [maxExpression, setMaxExpression] = useState<number>(0);
  const [showLegend, setShowLegend] = useState<boolean>(false);
  const [maxValuePerExpression, setMaxValuePerExpression] = useState<any>([]);
  const [percentagePerExpression, setPercentagePerExpression] = useState<any>([]);
  const [expressionData, setExpressionData] = useState<SlideInteractionsType | undefined>();
  const [graphWidth, setGraphWidth] = useState<number>(0);

  let resizeWindow = () => {
    setGraphWidth(chartContainerRef?.current?.clientWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const convertSeconds = (seconds: number | undefined) => {
    if (seconds !== undefined && seconds >= 1) {
      const hour = Math.floor(seconds / 3600);
      const min = Math.floor((seconds - hour * 3600) / 60);
      const sec = Math.floor(seconds % 60);
      return `${hour !== 0 ? hour + "h " : ""}${min !== 0 ? min + "m " : ""}${sec !== 0 ? sec + "s" : ""}`;
    } else {
      return "0s";
    }
  };

  const handleSortBy = (e: SelectChangeEvent) => {
    setSegmenyBy(+e.target.value);
  };

  const handleBackward = () => {
    if (slideNumber > 1) setSlideNumber((prev: number) => prev - 1);
  };

  const handleForward = () => {
    if (slideNumber < numOfPages) setSlideNumber((prev: number) => prev + 1);
  };

  const handleMouseMove = (event: any) => {
    if (expressionData?.duritationSec) {
      let leftOffset = event.target.offsetParent.offsetParent.offsetLeft;
      let topOffset = event.target.offsetParent.offsetParent.offsetTop;
      let lt = ((event.clientX - leftOffset) * (expressionData?.duritationSec + 1)) / chartContainerRef?.current?.offsetWidth;
      setLineTime(lt);
      if (event.clientX - leftOffset >= 0 && event.clientY - topOffset >= 0 && event.clientX - chartContainerRef?.current?.offsetWidth <= leftOffset)
        setCoords({
          x: event.clientX - leftOffset,
          y: event.clientY - topOffset,
        });
      pickDataTimeBased(combinedData, expressionData?.duritationSec, segmentBy, lt);
    }
  };

  const pickDataTimeBased = (data: any, slideDuration: number, segmentCount: number, time: number) => {
    let divider = (slideDuration + 1) / segmentCount;
    let choosenIndex = Math.floor(time / divider);

    const returnData = data.map((elem: any) => elem.data[choosenIndex]);
    setPickedData(returnData);
    return returnData;
  };

  const getExporessionValues = (): string[] => Object.values(EnumExpressionsNum).map((value: number) => value.toString());

  const makeSimpleChartData = (data: Array<any>): Array<number> => data?.map((d: any) => d?.length);

  const makeCombinedData = (data: SlideInteractionsType) => {
    getExporessionValues();
    const combinedArray: any = getExporessionValues()?.map((tag: string) => ({ tag: tag, data: makeSimpleChartData(makeSegmentedDataObject(data, segmentBy, tag)) }));
    // console.log("Segmented by: ", segmentBy, " - Combined array: ", combinedArray);
    setCombinedData(combinedArray);
  };

  const getSlideInteractionsBySlideNum = () => {
    SlideInteractions.getSlideInteractionsBySlideNum(courseId, lectureNum, slideNumber)
      .then(({ data }: any) => {
        const res: SlideInteractionsType = data.data;
        calculateMaxValuePerExpression(res);
        calculateMaxExpressionValue(res);
        setExpressionData(res);
        calculatePercentageNew(res);
        makeCombinedData(res);
        if (res) {
          convertSeconds(data.data.duritationSec + 1);
        }
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  useEffect(() => {
    getSlideInteractionsBySlideNum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideNumber]);

  useEffect(() => {
    calculateMaxValuePerExpression(expressionData!);
    calculateMaxExpressionValue(expressionData!);
    calculatePercentageNew(expressionData!);
    makeCombinedData(expressionData!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentBy]);

  const makeBarChartData = (dataArray: any, type: string) => dataArray?.map((data: any) => data[type]);

  const calculateMaxExpressionValue = (data: SlideInteractionsType) => {
    let max = 0;
    getExporessionValues().forEach((tag: string) => {
      makeSimpleChartData(makeSegmentedDataObject(data, segmentBy, tag)).forEach((expression: number) => {
        if (expression > max) max = expression;
      });
    });
    setMaxExpression(max);
    return max;
  };

  const calculateMaxValuePerExpression = (data: SlideInteractionsType) => {
    const returnArray: any = getExporessionValues().map((tag: string) => {
      let tagArray = makeSimpleChartData(makeSegmentedDataObject(data, segmentBy, tag));
      let max = 0;
      tagArray.forEach((value: number) => {
        if (value > max) max = value;
      });
      return { tag: tag, maxValue: max };
    });
    setMaxValuePerExpression(returnArray);
    return returnArray;
  };
  const calculatePercentageNew = (data: SlideInteractionsType) => {
    let overallSum = 0;
    let sumByTagArray: any = getExporessionValues().map((tag: string) => {
      let tagArray = makeBarChartData(makeDataObject(data), tag);
      let sumByTag = 0;
      tagArray.forEach((exp: Array<ExpressionType>) => {
        sumByTag += exp.length;
        overallSum += exp.length;
      });
      return sumByTag;
    });

    let returnArray: any = sumByTagArray.map((sum: number) => {
      const percent = overallSum !== 0 ? Math.round((sum / overallSum) * 100) : 0;
      return percent;
    });
    setPercentagePerExpression(returnArray);
    return returnArray;
  };

  const makeDataObject = (newData: SlideInteractionsType) => {
    let tagData: Array<any> = [];
    newData?.intervals?.forEach((interval: IntervalType, index: number) => {
      let one: Array<ExpressionType> = [];
      let two: Array<ExpressionType> = [];
      let three: Array<ExpressionType> = [];
      let four: Array<ExpressionType> = [];
      let five: Array<ExpressionType> = [];
      interval?.expressions?.forEach((expression: any) => {
        if (expression?.tag === "1") {
          one.push(expression);
        }
        if (expression?.tag === "2") {
          two.push(expression);
        }
        if (expression?.tag === "3") {
          three.push(expression);
        }
        if (expression?.tag === "4") {
          four.push(expression);
        }
        if (expression?.tag === "5") {
          five.push(expression);
        }
      });
      tagData.push({ "1": one, "2": two, "3": three, "4": four, "5": five });
    });
    return tagData;
  };

  const makeArrayOfUniqueObject = (tempArray: any, step: number) => {
    let combinedArray: any = [];
    for (let i = 0; i <= EnumSegment.MAX - step; i += step) {
      let slicedArray = tempArray.slice(i, i + step);
      let flattenArray = slicedArray.flat(1);
      let unique = [...new Map(flattenArray.map((item: any) => [item["id"], item])).values()];
      combinedArray.push(unique);
    }
    return combinedArray;
  };

  const makeSegmentedDataObject = (dataArray: SlideInteractionsType, segmentNum: number, tag: string) => {
    let returnArray: Array<ExpressionType> = [];
    let tempArray: Array<ExpressionType> = makeBarChartData(makeDataObject(dataArray), tag);
    if (segmentNum === EnumSegment.MAX) {
      returnArray = tempArray;
    } else if (segmentNum === EnumSegment.MIDDLE) {
      returnArray = [...makeArrayOfUniqueObject(tempArray, 2)];
    } else {
      returnArray = [...makeArrayOfUniqueObject(tempArray, 5)];
    }
    return returnArray;
  };

  return {
    convertSeconds,
    handleSortBy,
    segmentBy,
    slideNumber,
    handleBackward,
    handleForward,
    handleMouseMove,
    chartContainerRef,
    coords,
    lineTime,
    pickedData,
    maxExpression,
    setShowLegend,
    showLegend,
    combinedData,
    maxValuePerExpression,
    percentagePerExpression,
    expressionData,
    graphWidth,
    noPresentation,
  };
}

export type { SlideStatisticsServiceExport };

