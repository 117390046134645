import { Button, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import { useAppContext } from "../../../context/AuthContext";
import EnumRole from "../../../utils/enum/EnumRole";
import MessageType from "../../../utils/MessageType";
import Routes from "../../../utils/Routes";
import ButtonCustom from "../../shared/button-custom/ButtonCustom";
import OutlinedInputCustom from "../../shared/outlined-input-custom/OutlinedInputCustom";
import { User } from "./../../../controller";
import { validationSchema } from "./config";

const baseClass = "ac-login";

export default function RegisterStudent() {
  const history = useHistory();
  const { updateAccessToken, showMessage, updateRole } = useAppContext();

  const handleOnSubmit = ({ anonId, password }: any) => {
    User.userLogin(anonId, password)
      .then((res: any) => {
        const data = res?.data;
        if (data?.message) {
          showMessage(data.message, MessageType.SUCCESS);
          updateAccessToken(data.data);
          updateRole(EnumRole.STUDENT);
          history.push(Routes.HOME);
        }
      })
      .catch((err: any) => console.error(err));
  };

  return (
    <Grid className={`${baseClass}__root`} container item xs={12}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10} sm={8} md={3}>
          <Formik initialValues={{ anonId: "", password: "" }} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
            {({ handleBlur, values, touched, errors, handleChange, handleSubmit }) => (
              <Form>
                <div className={baseClass}>
                  <div className={`${baseClass}__title ${baseClass}__title--small`}>If you are already registered participant</div>
                  <div className={`${baseClass}__form`}>
                    <OutlinedInputCustom
                      placeholder="Anon ID"
                      name="anonId"
                      value={values.anonId}
                      onChange={handleChange}
                      aria-describedby="outlined-id-helper-text"
                      error={touched.anonId && Boolean(errors.anonId)}
                      onBlur={handleBlur}
                      fullWidth
                      className="ac-text-field"
                    />
                    <div className="error-text">{touched.anonId && Boolean(errors.anonId) && <label>{errors.anonId}</label>}</div>
                    <OutlinedInputCustom
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      aria-describedby="outlined-pass-helper-text"
                      type="password"
                      error={touched.password && Boolean(errors.password)}
                      onBlur={handleBlur}
                      fullWidth
                      className="ac-text-field"
                    />
                    <div className="error-text">{touched.password && Boolean(errors.password) && <label>{errors.password}</label>}</div>
                    <Button type="submit" variant="contained" className="v-btn ac-color-white ac-bordered-white btn-login" disabled={!values.anonId || !values.password}>
                      Login
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <p className={`${baseClass}__description`}>or</p>
          <p className={`${baseClass}__title ${baseClass}__title--small`}>Don't have an account?</p>
          <ButtonCustom onClick={() => history.push(Routes.OLD_OR_NEW_STUDENT)} gradient="light-blue" label="Register" />
        </Grid>
      </Grid>
    </Grid>
  );
}
