import { Box, Typography } from "@mui/material";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import ExpressionDto from "../../../../../interfaces/expression/ExpressionDto";
import EnumExpressionsText from "../../../../../utils/enum/EnumExpressionsText";
import FFIcon from "../../../../shared/icons/FFIcon";
import GreatIcon from "../../../../shared/icons/GreatIcon";
import LostIcon from "../../../../shared/icons/LostIcon";
import OffPointIcon from "../../../../shared/icons/OffPointIcon";
import QuestionMarkIcon from "../../../../shared/icons/QuestionMarkIcon";

interface RemoteItemType {
  expressionData: ExpressionDto[];
  expressionState: { [key: string]: boolean };
  onClick: (expStr: string) => void;
  title: string;
  name: string;
}

const CIRCULAR_SIZE = 95;
const CIRCULAR_VALUE = 85;

const expressionDataInit = { count: 0, percentCount: 0, totalExpressions: 0, expressionTag: 0, id: "0" };

export default function RemoteItem({ expressionData, onClick, expressionState, title, name, ...props }: CircularProgressProps & RemoteItemType) {
  const expressionDataByName: ExpressionDto = expressionData.find((item: any) => item.id === name) ?? expressionDataInit;
  const voted = expressionState[name];

  const handleExpressionClick = () => onClick(name);

  const mapExpressionToIcon = () => {
    switch (name) {
      case EnumExpressionsText.LOST:
        return <LostIcon />;
      case EnumExpressionsText.PAUSE:
        return <QuestionMarkIcon />;
      case EnumExpressionsText.GREAT:
        return <GreatIcon />;
      case EnumExpressionsText.FF:
        return <FFIcon />;
      case EnumExpressionsText.REW:
        return <OffPointIcon />;
      default:
        console.error("error expression icon");
        return;
    }
  };

  const mapExpressionPercentToColor = () => {
    switch (name) {
      case EnumExpressionsText.LOST:
        return "#f72f0a";
      case EnumExpressionsText.PAUSE:
        return "#f7df0a";
      case EnumExpressionsText.GREAT:
        return "#68d729";
      case EnumExpressionsText.FF:
        return "#a240df";
      case EnumExpressionsText.REW:
        return "#108dff";
      default:
        console.error("error expression color");
        return;
    }
  };

  return (
    <div className="remote-item">
      <div className={`remote-item remote-item--${name} ${voted && "remote-item--voted"}`}>
        <Box sx={{ position: "relative", display: "block" }}>
          <Typography variant="caption" component="div" style={{ textAlign: "center", position: "relative", top: "8px" }}>
            {expressionDataByName.count}
          </Typography>
          {expressionDataByName.percentCount > 0 && (
            <CircularProgress
              {...props}
              size={CIRCULAR_SIZE}
              thickness={2.2}
              variant="determinate"
              value={+expressionDataByName.percentCount / (100 / CIRCULAR_VALUE)}
              sx={{ transform: "rotate(-65deg) scale(1.05)!important ", color: `${mapExpressionPercentToColor()} !important`, position: "absolute", zIndex: 100 }}
            />
          )}
          <CircularProgress {...props} size={CIRCULAR_SIZE} thickness={0.5} variant="determinate" value={CIRCULAR_VALUE} sx={{ transform: "rotate(-65deg) !important", color: "#39a5c0 !important" }} />
          <Box
            sx={{
              top: "22px",
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box style={{ cursor: "pointer", zIndex: 101 }} onClick={handleExpressionClick}>
              {mapExpressionToIcon()}
            </Box>
          </Box>
        </Box>
        <div className="remote-item__title">{title || name}</div>
      </div>
    </div>
  );
}
