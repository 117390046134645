const OffPointIcon = () => (
  <svg width="50.0333" height="50.0333" viewBox="0 0 56.633 56.633" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M56.6321 28.3164C56.6321 33.9167 54.9713 39.3908 51.8599 44.0474C48.7486 48.7039 44.3264 52.3334 39.1524 54.4766C33.9784 56.6198 28.285 57.1809 22.7923 56.0884C17.2996 54.9959 12.2541 52.2988 8.294 48.3389C4.3339 44.3789 1.63694 39.3335 0.544243 33.8408C-0.548449 28.3481 0.0120652 22.655 2.15508 17.481C4.2981 12.3069 7.92738 7.88447 12.5838 4.77295C17.2402 1.66143 22.7147 0.00019778 28.315 0C35.825 -4.68208e-09 43.0273 2.98321 48.3377 8.29346C53.6481 13.6037 56.6319 20.8064 56.6321 28.3164Z"
      fill="url(#paint0_linear_2014_2)"
    />
    <path
      d="M15.1851 20.9773C15.0651 21.532 14.5483 21.911 13.9853 21.8555C13.4499 21.8 13.0069 21.3379 13.0069 20.7832C12.9977 18.5555 12.9977 16.337 13.0069 14.1092C13.0069 13.4992 13.4961 13.0092 14.1145 13.0092C16.3204 13 18.5355 13 20.7506 13.0092C21.369 13.0092 21.8489 13.4992 21.8581 14.1C21.8581 14.7193 21.3782 15.2093 20.7321 15.2093C20.123 15.2185 18.1663 15.2185 17.0126 15.237C17.714 15.8933 22.7996 20.5891 24.507 22.7429C26.1868 24.869 27.5344 27.1891 28.4019 29.7681C28.4204 29.8328 28.4573 29.953 28.5035 29.953C28.5496 29.953 28.5773 29.8236 28.605 29.7589C29.6664 26.653 31.3923 23.9446 33.5521 21.4858C35.3518 19.4336 39.7913 15.4496 39.9943 15.274C39.6805 15.274 37.4839 15.2185 36.3763 15.2185C35.6472 15.2185 35.1488 14.7563 35.158 14.1C35.1488 13.4529 35.6472 13 36.3579 13C38.4991 13 40.6496 13 42.7909 13C43.5201 13 44 13.4807 44 14.2109C44 16.3555 44 18.5 44 20.6446C44 21.3471 43.5385 21.8463 42.9017 21.8555C42.2464 21.8647 41.7849 21.3563 41.7849 20.6261C41.7849 19.4244 41.7849 16.8084 41.7849 16.6328C40.9727 17.363 40.1974 18.0471 39.4313 18.7404C37.4931 20.4967 35.6103 22.3177 34.0043 24.3975C31.8907 27.1429 30.4694 30.1934 29.8971 33.632C29.6849 34.9077 29.6018 36.1925 29.6018 37.4867C29.611 39.2892 29.611 41.0825 29.6018 42.885C29.6018 43.4674 29.1772 43.9296 28.6142 43.9943C28.0697 44.0497 27.5528 43.6985 27.4236 43.1531C27.3867 43.0144 27.3867 39.1783 27.3867 37.4127C27.3867 34.4917 26.8883 31.6631 25.7253 28.9732C24.6178 26.4312 23.0118 24.2219 21.129 22.2068C19.32 20.2656 17.3172 18.5185 15.342 16.753C15.3143 17.5479 15.2128 20.8387 15.1851 20.9773Z"
      fill="#1F3C52"
    />
    <defs>
      <linearGradient id="paint0_linear_2014_2" x1="0" y1="28.3162" x2="56.6321" y2="28.3162" gradientUnits="userSpaceOnUse">
        <stop stopColor="#24B3FF" />
        <stop offset="1" stopColor="#006EFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default OffPointIcon;
