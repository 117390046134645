import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { Page } from "react-pdf";
import { useHistory } from "react-router-dom";
import { Lecture } from "../../../controller";
import { SlideInteractions } from "../../../controller/SlideInteraction";
import Colors from "../../../utils/Colors";
import EnumExpressionColors from "../../../utils/enum/EnumExpressionColors";
import EnumExpressions from "../../../utils/enum/EnumExpressions";
import EnumExpressionsNum from "../../../utils/enum/EnumExpressionsNum";
import EnumExpressionsText from "../../../utils/enum/EnumExpressionsText";
import Routes from "../../../utils/Routes";
import UserRole from "../../../utils/UserRole";
import { useEffectOnce } from "../../../utils/Utils";
import AnswerIcon from "../icons/AnswerIcon";
import DiscussonIcon from "../icons/DiscussonIcon";
import QuestionIcon from "../icons/QuestionIcon";

const colorStat = [
  { id: 1, color: EnumExpressionColors[1] },
  { id: 2, color: "#FFE212" },
  { id: 3, color: EnumExpressionColors[3] },
  { id: 4, color: EnumExpressionColors[4] },
  { id: 5, color: EnumExpressionColors[5] },
];

interface StatsCardProps {
  pageNumber: number;
  courseId: number;
  lectureNum: number;
  showAll: boolean;
  numOfPages: number;
  showSlide?: boolean;
  noPresentation?: boolean;
}

interface DataType {
  expressionMap: Record<number, number>;
  totalExpressions: number;
  numQuestions: number;
  numAnswers: number;
  numDiscussions: number;
  speakerName: string;
}

const dataInit = {
  expressionMap: {},
  totalExpressions: 1,
  numQuestions: 0,
  numAnswers: 0,
  numDiscussions: 0,
  speakerName: "",
};

interface IntervalType {
  intervalNum: number;
  startInSec: string;
  endInSec: string;
  expressions: Array<ExpressionType | undefined>;
}

interface ExpressionType {
  id: number;
  activatedAtSec: number;
  tag: string;
}

interface SlideInteractionsType {
  slideNum: string;
  startedAt: string;
  duritationSec: number;
  intervals: [IntervalType];
}

export default function StatsCard(props: StatsCardProps) {
  const { noPresentation, pageNumber, courseId, lectureNum, showAll, numOfPages, showSlide } = props;
  const [{ expressionMap, totalExpressions, numQuestions, numAnswers, numDiscussions, speakerName }, setData] = useState<DataType>(dataInit);
  const baseClass = showAll ? "stats-card-show" : "stats-card";
  const history = useHistory();
  const { isTeacher } = UserRole();
  const [percentagePerExpression, setPercentagePerExpression] = useState<any>([]);
  const showOldStatsPercent = false;

  const makeBarChartData = (dataArray: any, type: string) => dataArray?.map((data: any) => data[type]);

  const makeDataObject = (newData: SlideInteractionsType) => {
    let tagData: Array<any> = [];
    newData?.intervals?.forEach((interval: IntervalType, index: number) => {
      let one: Array<ExpressionType> = [];
      let two: Array<ExpressionType> = [];
      let three: Array<ExpressionType> = [];
      let four: Array<ExpressionType> = [];
      let five: Array<ExpressionType> = [];
      interval?.expressions?.forEach((expression: any) => {
        if (expression?.tag === "1") {
          one.push(expression);
        }
        if (expression?.tag === "2") {
          two.push(expression);
        }
        if (expression?.tag === "3") {
          three.push(expression);
        }
        if (expression?.tag === "4") {
          four.push(expression);
        }
        if (expression?.tag === "5") {
          five.push(expression);
        }
      });
      tagData.push({ "1": one, "2": two, "3": three, "4": four, "5": five });
    });
    return tagData;
  };

  const getExporessionValues = (): string[] => Object.values(EnumExpressionsNum).map((value: number) => value.toString());

  const calculatePercentageNew = (data: SlideInteractionsType) => {
    let overallSum = 0;
    let sumByTagArray: any = getExporessionValues().map((tag: string) => {
      let tagArray = makeBarChartData(makeDataObject(data), tag);
      let sumByTag = 0;
      tagArray.forEach((exp: Array<ExpressionType>) => {
        sumByTag += exp.length;
        overallSum += exp.length;
      });
      return sumByTag;
    });
    let returnArray: any = sumByTagArray.map((sum: number) => {
      const percent = overallSum !== 0 ? Math.round((sum / overallSum) * 100) : 0;
      return percent;
    });
    setPercentagePerExpression(returnArray);
    return returnArray;
  };

  const getSlideInteractionsBySlideNum = () => {
    SlideInteractions.getSlideInteractionsBySlideNum(courseId, lectureNum, pageNumber)
      .then(({ data }: any) => {
        const res: SlideInteractionsType = data.data;
        calculatePercentageNew(res);
      })
      .catch((err: any) => console.log(err));
  };

  const getSlideStats = () => {
    Lecture.getExpressionsForSlide(courseId, lectureNum, pageNumber)
      .then((data: DataType) => setData(data))
      .catch((err: any) => console.log(err));
  };

  useEffectOnce(() => {
    getSlideInteractionsBySlideNum();
    getSlideStats();
  });

  const getPercent = (expressions: number, total: number) => {
    if (total === 0) return 0;
    return Math.round((expressions / total) * 100);
  };

  const getEnumExpressionLabels = (id: string) => {
    switch (id) {
      case EnumExpressionsText.REW:
        return "OFF";
      case EnumExpressionsText.LOST:
        return "DIS";
      case EnumExpressionsText.PAUSE:
        return "N-CLEAR";
      case EnumExpressionsText.GREAT:
        return "AGREE";
      case EnumExpressionsText.FF:
        return "WRAP";
      default:
        return "";
    }
  };

  return (
    <Card
      className={`${baseClass}`}
      onClick={() => {
        isTeacher && history.push({ pathname: Routes.TEACHER_SLIDE_STATS, state: { courseId, lectureNum, pageNumber, numOfPages, noPresentation } });
      }}
    >
      <CardContent style={{ padding: 0, display: "flex", justifyContent: "center" }}>
        {noPresentation ? (
          <Grid container item>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: Colors.WHITE }} />
          </Grid>
        ) : isTeacher || showSlide ? (
          <Page pageNumber={pageNumber} height={180} />
        ) : (
          <Grid container item justifyContent="center" alignItems="center" style={{ minHeight: 180, width: "100%", backgroundColor: Colors.NAVY_SOLID, color: Colors.WHITE }}>
            <Grid item xs={12}>
              {`Slide No. ${pageNumber}`}
            </Grid>
          </Grid>
        )}
        <Grid container className="interactions-container" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid container item xs={3} justifyContent="center" alignItems="center">
            <QuestionIcon sideLength="12px" />
            <Typography fontWeight={500} fontSize="9px" ml="1px">
              {numQuestions}
            </Typography>
          </Grid>
          <Grid container item xs={3} justifyContent="center" alignItems="center">
            <AnswerIcon sideLength="12px" />
            <Typography fontWeight={500} fontSize="9px" ml="1px">
              {numAnswers}
            </Typography>
          </Grid>
          <Grid container item xs={3} justifyContent="center" alignItems="center">
            <DiscussonIcon sideLength="12px" />
            <Typography fontWeight={500} fontSize="9px" ml="1px">
              {numDiscussions}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="speaker-name-container" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Grid container item xs={12} justifyContent="center" alignItems="center">
            <Typography color={Colors.BLUE_DIANNE} fontWeight={500} fontSize="10px">
              {speakerName || pageNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="hover-container" justifyContent="space-around">
          <Grid container className="stats-container" flexDirection="row" justifyContent="center">
            {colorStat.map(({ id, color }) => {
              const newPercent = percentagePerExpression && percentagePerExpression[id - 1];
              const percent = showOldStatsPercent ? getPercent(expressionMap[id], totalExpressions) : newPercent;
              return <Grid key={id} item xs={(percent / 10) * 1.2} style={{ background: color }} />;
            })}
          </Grid>
          {colorStat.map(({ id, color }) => {
            const newPercent = percentagePerExpression && percentagePerExpression[id - 1] ? `${percentagePerExpression[id - 1]}%` : "0%";
            const oldPercentExists = expressionMap && Object.keys(expressionMap).length !== 0 && `${getPercent(expressionMap[id], totalExpressions)}%`;
            const percent = showOldStatsPercent ? oldPercentExists : newPercent;
            return (
              <Grid key={id} container flexDirection="column" item xs={2} justifyContent="center" rowGap={1}>
                <Grid item style={{ color: color, fontSize: 12, fontWeight: 600 }}>
                  <Typography fontSize="10px" fontWeight={600}>
                    {percent}
                  </Typography>
                </Grid>
                <Grid item style={{ color: "white", fontSize: 9, fontWeight: 500 }}>
                  <Typography fontSize="7px" fontWeight={600}>
                    {getEnumExpressionLabels(EnumExpressions[id])}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}
