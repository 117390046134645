import { Grid } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT_STYLE, parseStartEndTime } from "../../../../utils/Utils";
import GridCard from "../../../shared/grid-card/GridCard";
import MyLecturesService, { MyLecturesServiceType } from "../my-lectures/MyLecturesService";

export default function Statistics() {
  const baseClass = "statistics";
  const history = useHistory();
  const { updateSelectedLecture } = useAppContext();
  const { lectures }: MyLecturesServiceType = MyLecturesService();

  const sortLectures = (lectureArray: LectureDto[]) => {
    return lectureArray.sort((a: LectureDto, b: LectureDto) => {
      return moment(a.startTime).valueOf() - moment(b.startTime).valueOf();
    });
  };

  return (
    <div className={`${baseClass}`}>
      <div className={`${baseClass}__subtext`}>Statistics</div>
      <Grid container justifyContent="flex-start" alignItems="flex-start" rowSpacing={1} columnSpacing={1}>
        {sortLectures(lectures).map(({ id, name, startTime, endTime, lectureNum, state }: LectureDto, index: number) => (
          <Grid key={id} item>
            <GridCard
              position={index + 1}
              title={startTime ? moment(startTime).format(DATE_FORMAT_STYLE) : ""}
              subtitle={parseStartEndTime(startTime, endTime)}
              cardAction={() => {
                updateSelectedLecture({ id, name, lectureNum });
                history.push(Routes.LECTURE_STATS.replace(":lectureNum", String(lectureNum)));
              }}
              name={name}
              active={false}
              color={Colors.NAVY_SOLID}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
