import { mdiPlay } from "@mdi/js";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import EnumLectureState from "../../../../utils/enum/EnumLectureState";
import Routes from "../../../../utils/Routes";
import { AddButton } from "../../../shared/components/styledComponents";
import AddIcon from "../../../shared/icons/AddIcon";
import SvgIconWrapper from "../../../shared/svg-icon-wrapper/SvgIconWrapper";
import ClassId from "./class-id/ClassId";
import ClassInfo from "./class-info/ClassInfo";
import ClassPermission from "./class-permission/ClassPermission";
import ClassWaitingRoom from "./class-waiting-room/ClassWaitingRoom";
import ClassStatusService, { ClassStatusServiceType } from "./ClassStatusService";

export default function ClassStatus() {
  const { isMobile } = useQueryMedia();
  const history = useHistory();
  const { liveLecture, setLiveLecture, handleEndLecture, handleContinuePresentation, newConnectionWarning, startMeeting, closeWarningStartLecture, getLectures }: ClassStatusServiceType =
    ClassStatusService();

  const iconPlay = <SvgIconWrapper icon={mdiPlay} />;

  const cardArray = (liveLecture?: LectureDto) =>
    isMobile
      ? [
          { title: "Meeting Code", content: <ClassId lectureNum={liveLecture?.lectureNum} getLectures={getLectures} /> },
          {
            title: "Waiting room",
            content: <ClassWaitingRoom lectureNum={liveLecture?.lectureNum} newConnectionWarning={newConnectionWarning} closeWarningStartLecture={closeWarningStartLecture} />,
          },
          { title: "Permissions", content: <ClassPermission liveLecture={liveLecture} setLiveLecture={setLiveLecture} /> },
          { title: "Meeting info", content: <ClassInfo lectureNum={liveLecture?.lectureNum} /> },
        ]
      : [
          { title: "Meeting Code", content: <ClassId lectureNum={liveLecture?.lectureNum} getLectures={getLectures} /> },
          { title: "Permissions", content: <ClassPermission liveLecture={liveLecture} setLiveLecture={setLiveLecture} /> },
          { title: "Meeting info", content: <ClassInfo lectureNum={liveLecture?.lectureNum} /> },
          {
            title: "Waiting room",
            content: <ClassWaitingRoom lectureNum={liveLecture?.lectureNum} newConnectionWarning={newConnectionWarning} closeWarningStartLecture={closeWarningStartLecture} />,
          },
        ];

  return (
    <Grid xl={11} sx={{ mt: -1, ml: isMobile ? 0 : -4 }} justifyContent="center">
      <div className="class-status">
        <Box sx={{ color: "white", textAlign: "center" }}>
          <Grid container item xs={12} justifyContent={isMobile || liveLecture ? "center" : "space-between"} alignItems="center" className={`class-status-title-container${isMobile ? "-mobile" : ""}`}>
            <Grid item xs={isMobile ? 0 : 3}></Grid>
            <Grid item xs={isMobile ? 12 : 6}>
              {!liveLecture ? (
                <Typography component="span" variant="body1">
                  No meeting is scheduled for this time.
                </Typography>
              ) : (
                <Typography variant="h6" className="class-status-title">
                  {liveLecture && `Meeting: ${liveLecture.name}`}
                </Typography>
              )}
            </Grid>
            {!isMobile && (
              <Grid item xs={isMobile ? 12 : 3}>
                {!liveLecture && (
                  <AddButton startIcon={<AddIcon />} onClick={() => history.push(Routes.TEACHER_MY_LECTURES)}>
                    Add a New Meeting
                  </AddButton>
                )}
              </Grid>
            )}
          </Grid>
          {/* <AutocreateMeetingDialog open={showAutoCreateMeetingDialog} title="Autocreate meeting" close={() => setShowAutoCreateMeetingDialog(false)} confirmAction={() => getLectures()} /> */}
        </Box>

        <>
          <Grid
            container
            textAlign="center"
            justifyContent="center"
            alignItems="flex-start"
            alignSelf="center"
            rowGap={isMobile ? 1 : 1.5}
            columnGap={1.5}
            style={{ paddingTop: "0px", paddingBottom: "8px" }}
          >
            {cardArray(liveLecture).map(({ title, content }: any, i: number) => (
              <Grid container item xs={12} sm={5.5} md={5.5} lg={5.5} key={i} justifyContent="center">
                <Grid key={i} item className="class-status-card" xs={12}>
                  <Grid container direction="column">
                    {(!isMobile || title === "Waiting room") && (
                      <>
                        <Grid item className="s-card-title" padding={1}>
                          {title}
                        </Grid>
                        <hr className="class-status-card-divider" />
                      </>
                    )}
                    <Grid item padding={isMobile ? 1 : 3}>
                      {content}
                    </Grid>
                  </Grid>
                </Grid>
                {!isMobile &&
                  i === 2 &&
                  (liveLecture?.state !== EnumLectureState.STARTED ? (
                    <Grid container item padding={12} p={7} justifyContent="center">
                      <Button
                        onClick={startMeeting}
                        variant="contained"
                        endIcon={iconPlay}
                        style={{ border: "1px solid white", backgroundColor: Colors.GREEN_SOLID, color: "white" }}
                        className="button-custom-action"
                      >
                        Start Meeting
                      </Button>
                    </Grid>
                  ) : (
                    <Grid container paddingTop={isMobile ? 1 : 2} justifyContent="space-around" alignItems="center">
                      <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5} textAlign="center" mt={isMobile ? 0 : 0.5}>
                        <Button
                          onClick={handleContinuePresentation}
                          variant="contained"
                          endIcon={iconPlay}
                          style={{ border: "1px solid white", color: "white", backgroundColor: "#8bc63f" }}
                          className="button-custom-action"
                        >
                          {`Continue${!isMobile ? " Meeting" : ""}`}
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5.5} lg={5.5} xl={5.5} textAlign="center" mt={0.5}>
                        <Button
                          onClick={handleEndLecture}
                          variant="contained"
                          style={{ border: "1px solid white", color: "white", backgroundColor: Colors.RED_LIGHT }}
                          className="button-custom-action"
                        >
                          {`End${!isMobile ? " Meeting" : ""}`}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            ))}
          </Grid>
          {isMobile &&
            (liveLecture?.state !== EnumLectureState.STARTED ? (
              <Grid container paddingTop={isMobile ? 0 : 2} justifyContent="space-around" alignItems="center">
                {/* <Grid item xs={5.5} sm={5.5} md={5.5} lg={2.5} xl={2.5}>
              <Button
              variant="contained"
              onClick={() => history.push(`${Routes.TEACHER_MY_LECTURES}/edit/${liveLecture.lectureNum}`)}
              endIcon={iconPlay}
              style={{ backgroundColor: Colors.YELLOW_SOLID }}
              className="button-custom-action"
              >
              Edit Meeting
              </Button>
            </Grid> */}

                <Grid item xs={8} sm={8} md={8} lg={2.5} xl={2.5}>
                  <Button
                    onClick={startMeeting}
                    variant="contained"
                    endIcon={iconPlay}
                    style={{ border: "1px solid white", color: "white", backgroundColor: Colors.GREEN_SOLID }}
                    className="button-custom-action"
                  >
                    Start Meeting
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container paddingTop={isMobile ? 0 : 2} justifyContent="space-around" alignItems="center">
                <Grid item xs={5.5} sm={5.5} md={5} lg={4} xl={4} textAlign="center" mt={0}>
                  <Button
                    onClick={handleContinuePresentation}
                    variant="contained"
                    endIcon={iconPlay}
                    style={{ border: "1px solid white", color: "white", backgroundColor: "#8bc63f" }}
                    className="button-custom-action"
                  >
                    {`Continue${!isMobile ? " Meeting" : ""}`}
                  </Button>
                </Grid>
                <Grid item xs={5.5} sm={5.5} md={5} lg={4} xl={4} textAlign="center" mt={0}>
                  <Button onClick={handleEndLecture} variant="contained" style={{ border: "1px solid white", color: "white", backgroundColor: Colors.RED_LIGHT }} className="button-custom-action">
                    {`End${!isMobile ? " Meeting" : ""}`}
                  </Button>
                </Grid>
              </Grid>
            ))}
        </>
      </div>
    </Grid>
  );
}
