import { Box } from "@mui/material";
import { CreateText, CreateTitle } from "./HowItWorkComponents";

export default function HowItWorkFAQ() {
  return (
    <Box sx={{ color: "white" }}>
      <CreateTitle isFirst title="What is this platform about?" />
      <CreateText>
        We live in a world, where speaking freely is becoming increasingly difficult. Due to various social and peer pressures, many great ideas are not heard. Consequently, the conformity rules. Our
        platform provides a space for free (anonymous) but at the same time responsible (accountable) communication.
      </CreateText>
      <CreateTitle title="Don’t we already have too many learning platforms?" />
      <CreateText>
        Yes, but our platform addresses a specific need that has been overlooked: how to create an environment where participants can feel free to express their opinions openly and respectfully. As
        such, we see our platform as complementary to existing learning and communication platforms (such as Zoom, Teams, etc.). One can think of our platform as a tool that can ‘anonymize’
        participants and enable a different and more open communication.
      </CreateText>
      <CreateTitle title="What’s new about this platform?" />
      <CreateText>
        <ul>
          <li>Provides continuous real-time feedback to teachers during lectures. </li>
          <li>Makes it easy to quickly find the most relevant questions and answers. </li>
          <li>Eliminates endless and often disconnected discussion threads. </li>
          <li>Enables space for open (anonymous) conversation. </li>
        </ul>
      </CreateText>
      <CreateTitle title="Aren’t there other learning platforms that also offer anonymous interactions? " />
      <CreateText>
        There are platforms that offer students an option to make posts anonymously, but students are not truly anonymous since either teachers/schools or platforms know their identity. In contrast,
        our platform does not require any personally identifiable information from you when you register, such as your email or your phone number.
      </CreateText>
      <CreateTitle title="What are the benefits of being anonymous? " />
      <CreateText>
        There are several benefits. First, anonymity allows you to ask any question, even if the question appears dumb or too simple to you peers. Second, if you post an answer and it turns out that
        it is not correct, anonymity protects you from embarrassment of being wrong. Third, anonymous communication means that the power of arguments should always matter more than the power of
        individuals that make the arguments.
      </CreateText>
      <CreateTitle title="Wouldn’t giving complete anonymity to students results in chaos and offensive behavior? " />
      <CreateText>
        Yes, that is quite possible but only if anonymity is detached from accountability. However, we developed an algorithm that allows users to be both anonymous and accountable for their actions.
        Therefore, if a user makes disrespectful or offensive postings, the user shall be accountable for those postings and the user’s reputation will be lowered. That means that user’s future
        postings might not be allowed for some time.
      </CreateText>
      <CreateTitle title="Isn’t accountable anonymity an oxymoron? " />
      <CreateText>It was until we created this platform.</CreateText>
      <CreateTitle title="Thanks for making me anonymous but I would like to get recognition for all the work I’ve put into the course." />
      <CreateText>
        We understand that everyone should be recognized for contributions that they make. Even though you are anonymous, it is possible to share your ‘transcript’ with your teacher (using standard
        cryptographic methods) and by providing your transcript we still would not know your identity. The transcript however would not contain information that if revealed would affect (de-anonymize)
        other students.
      </CreateText>
      <CreateTitle title="Who are the moderators? " />
      <CreateText>Every participant is a moderator. This is a self-moderated platform. There are no external moderators.</CreateText>
      <CreateTitle title="Who can see Q/A/Ds?" />
      <CreateText>
        Only the teacher and the students that have registered for a particular course. Although only two participants can be involved in any specific discussion, the content of every discussion is
        open to everyone.
      </CreateText>
      <CreateTitle title="Can I use my AnonID that I obtained for one course to participate in another course? " />
      <CreateText>Yes, but the teacher of the new course would have to approve it.</CreateText>
      <CreateTitle title="What is the best way for a teacher to share the meeting Code? " />
      <CreateText>
        The easiest way is to share the screen with students that are attending the meeting and show them the meeting Code.
        <br />
        <br />
        When students are registering for the first time, the meeting Code should be sent to the students using email, text, Zoom chat, etc. In this way the teacher can keep the list of students who
        received their AnonIDs. We recommend that the teacher issues new AnonIDs only during the first class, or at most first few classes.
      </CreateText>
      <CreateTitle title="What about pseudonyms?" />
      <CreateText>
        In contrast to AnonIDs, pseudonyms are issued at the beginning of every new class. Teachers should make sure that they don’t issue more pseudonyms than there are students present in the class.
      </CreateText>
    </Box>
  );
}
