import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import { useAppContext } from "../../../context/AuthContext";
import { Public } from "../../../controller/public";
import MessageType from "../../../utils/MessageType";
import Routes from "../../../utils/Routes";
import ButtonCustom from "../../shared/button-custom/ButtonCustom";
import OutlinedInputCustom from "../../shared/outlined-input-custom/OutlinedInputCustom";
import { initValues, validationSchema } from "./config";

const baseClass = "ac-login";
export default function OldOrNewStudent() {
  const history = useHistory();
  const { accessToken, showMessage } = useAppContext();

  const handleOnSubmit = ({ lectureCode }: any) => {
    Public.checkClassId(lectureCode)
      .then((res: any) => {
        history.push({ pathname: Routes.PICK_CARD, state: { classId: lectureCode } });
      })
      .catch((err: any) => {
        showMessage("Sorry but there is no meeting with this meeting code", MessageType.ERROR);
      });
  };

  return (
    <Grid container justifyContent="center" sx={{ marginTop: "30vh" }}>
      <Grid item xs={10} sm={9} md={7}>
        <Formik initialValues={initValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
          {({ handleBlur, values, touched, errors, handleChange }) => (
            <Form>
              <div className={baseClass}>
                <div>
                  <OutlinedInputCustom
                    placeholder="Enter Meeting Code"
                    name="lectureCode"
                    value={values.lectureCode}
                    onChange={handleChange}
                    aria-describedby="outlined-id-helper-text"
                    error={touched.lectureCode && Boolean(errors.lectureCode)}
                    onBlur={handleBlur}
                    fullWidth
                    className="ac-text-field"
                  />
                  <div className="error-text">{touched.lectureCode && Boolean(errors.lectureCode) && <label>{errors.lectureCode}</label>}</div>
                  <ButtonCustom type="submit" gradient="green" disabled={!values.lectureCode} label="Join" textColor="black" fontWeight="800" />
                  {accessToken && (
                    <div className={`${baseClass}__explanation`}>
                      In order to join a meeting, that meeting has to be live. If you are already in a meeting and want to join a new meeting, you will be logged out from the meeting that you were
                      attending.
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}
