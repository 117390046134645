import { useEffect, useState } from "react";
import { useAppContext } from "../context/AuthContext";
import { Lecture } from "../controller";
import { SlideInteractions } from "../controller/SlideInteraction";

interface UseLectureTypeProps {
  lectureNum: number | undefined;
}
interface UseLectureTypeExport {
  presentationBlob: string;
  noPresentation: boolean;
  slides: any;
}
export default function useLectureType({ lectureNum }: UseLectureTypeProps): UseLectureTypeExport {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const [presentationBlob, setPresentationBlob] = useState<string>("");
  const [noPresentation, setNoPresentation] = useState<boolean>(false);
  const [slides, setSlides] = useState<any>([]);

  useEffect(() => {
    if (courseId && lectureNum) {
      downloadPresentation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, lectureNum]);

  const downloadPresentation = async () => {
    const blob = await Lecture.downloadPresentation(courseId, lectureNum);
    if (blob) {
      setPresentationBlob(blob);
    } else {
      existSlideInteraction();
    }
  };

  const existSlideInteraction = () => {
    SlideInteractions.existSlideInteraction(courseId, lectureNum)
      .then(({ data }: any) => {
        setNoPresentation(Boolean(data.data));
        setSlides(data.data);
      })
      .catch((err: any) => console.log(err));
  };

  return { presentationBlob, noPresentation, slides };
}
export type { UseLectureTypeProps, UseLectureTypeExport };
