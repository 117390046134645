import { useState } from "react";
import { useAppContext } from "../../../../context/AuthContext";
import { Teacher, User } from "../../../../controller";
import ChangePasswordType from "../../../../interfaces/change-password/ChangePasswordType";
import MessageType from "../../../../utils/MessageType";
import { useEffectOnce } from "../../../../utils/Utils";
import { INIT_VALUES } from "./config";

interface ISettingsService {
  handleOnSubmit: (val: ChangePasswordType, { resetForm }: any) => void;
  isModerator: boolean;
  handleToogleModerator: (e: any) => void;
}

export default function SettingsService(): ISettingsService {
  const { showMessage } = useAppContext();
  const [isModerator, setIsModerator] = useState<boolean>(false);

  useEffectOnce(() => {
    getCurrentTeacher();
  });

  const handleToogleModerator = (e: any) => {
    Teacher.updateIsModerator(e.target.checked)
      .then(({ data }: any) => {
        showMessage(data, MessageType.SUCCESS);
        setIsModerator(!e.target.checked);
      })
      .catch((err: any) => console.error(err));
  };

  const getCurrentTeacher = () => {
    Teacher.getCurrentTeacher()
      .then(({ data }: any) => {
        setIsModerator(data.data.isModerator);
      })
      .catch((err: any) => console.error(err));
  };

  const handleOnSubmit = (values: ChangePasswordType, { resetForm }: any) => {
    User.changePassword(values)
      .then(({ data }: any) => {
        if (data?.message) {
          resetForm(INIT_VALUES);
          showMessage(data.message, MessageType.SUCCESS);
        }
      })
      .catch((err: any) => console.error(err));
  };

  return { handleOnSubmit, isModerator, handleToogleModerator };
}

export type { ISettingsService };
