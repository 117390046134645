import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import Colors from "../../../../utils/Colors";
import SelectCustom from "../../../shared/select-custom/SelectCustom";
import { optionsSortBy } from "../../student-and-teacher/my-answers/config";
import QuestionAnswersAnswer from "./QuestionAnswersAnswer";
import QuestionAnswersQuestion from "./QuestionAnswersQuestion";

export default function QuestionAnswers() {
  const [sortBy, setSortBy] = useState<string>(String(optionsSortBy[0].id));
  const [questionPosted, setQuestionPosted] = useState<boolean>(false);
  const location = useLocation();
  const { isMobile } = useQueryMedia();

  const handleChangeSelect = (event: SelectChangeEvent) => setSortBy(event.target.value);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" className="question-answers">
      <Grid item xs={12} sm={12} md={11} lg={10} xl={10}>
        <QuestionAnswersQuestion setQuestionPosted={setQuestionPosted} locationState={location?.state} />
        <Grid container justifyContent="space-between" alignItems="center" textAlign="center" mt={isMobile ? 0 : 2} mb={1}>
          <Typography sx={{ fontSize: "18px" }} color={Colors.PICTON_BLUE}>
            ANSWERS:
          </Typography>
          <SelectCustom title="Sort by:" value={sortBy} handleChange={handleChangeSelect} options={optionsSortBy} />
        </Grid>
        <QuestionAnswersAnswer questionPosted={questionPosted} sortBy={sortBy} />
      </Grid>
    </Grid>
  );
}
