import { mdiAccountQuestion } from "@mdi/js";
import Icon from "@mdi/react";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import TooltipStyled from "../tooltip/TooltipStyled";
import UserInfoService from "./UserInfoService";

export default function UserInfo() {
  const { pseudonym, open, handleToggle, handleClose } = UserInfoService();
  if (!pseudonym) return null;

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <TooltipStyled tooltipText={pseudonym} open={open} onClose={handleClose} PopperProps={{ disablePortal: true }} disableFocusListener disableHoverListener disableTouchListener>
          <IconButton onClick={handleToggle}>
            <Tooltip placement="left" title="Who am I?">
              <Icon path={mdiAccountQuestion} color="white" size={1} />
            </Tooltip>
          </IconButton>
        </TooltipStyled>
      </div>
    </ClickAwayListener>
  );
}
