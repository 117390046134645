import { useEffect, useState } from "react";
import { wsEvents } from "../../../../../constants";
import { on } from "../../../../../constants/events";
import { useAppContext } from "../../../../../context/AuthContext";
import { Lecture, WaitingRoomSocket } from "../../../../../controller";
import { EVENT_UPDATE_CLASS_INFO } from "../../../../../utils/Utils";

interface ClassInfoObjProps {
  pseudonyms: number;
  accountsInLecture: number;
  accountsInCourse: number;
  registeredStudents: number;
}

interface ClassInfoServiceExport {
  classInfoObj: ClassInfoObjProps;
}

export default function ClassInfoService({ lectureNum }: { lectureNum?: number }): ClassInfoServiceExport {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const [classInfoObj, setClassInfoObj] = useState<ClassInfoObjProps>({ pseudonyms: 0, accountsInLecture: 0, accountsInCourse: 0, registeredStudents: 0 });

  useEffect(() => {
    if (lectureNum) {
      handleUpdate();
      on(EVENT_UPDATE_CLASS_INFO, handleUpdate);

      WaitingRoomSocket.connect({ query: { courseId, lectureNum } });
      WaitingRoomSocket.on(wsEvents.recv.NEW_STUDENTS_ADMITTED, handleUpdate);
      WaitingRoomSocket.on(wsEvents.recv.NON_REGISTERED_STUDENTS_ADMITTED, handleUpdate);
      WaitingRoomSocket.on(wsEvents.recv.REGISTERED_STUDENTS_ADMITTED, handleUpdate);
    }

    return () => {
      WaitingRoomSocket.off(wsEvents.recv.NEW_STUDENTS_ADMITTED, handleUpdate);
      WaitingRoomSocket.off(wsEvents.recv.NON_REGISTERED_STUDENTS_ADMITTED, handleUpdate);
      WaitingRoomSocket.off(wsEvents.recv.REGISTERED_STUDENTS_ADMITTED, handleUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureNum]);

  const handleUpdate = () => {
    Lecture.classStatusInfo(courseId, lectureNum)
      .then((res: any) => {
        const { attending, new_accounts_lec, new_accounts_course, accounts_course } = res.data.data;
        setClassInfoObj({
          pseudonyms: attending,
          accountsInLecture: new_accounts_lec,
          accountsInCourse: new_accounts_course,
          registeredStudents: accounts_course,
        });
      })
      .catch((err: any) => console.log(err));
  };

  return { classInfoObj };
}

export type { ClassInfoServiceExport, ClassInfoObjProps };
