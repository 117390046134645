import { AppBar, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import Routes from "../../../utils/Routes";
import LogoIcon from "../icons/LogoIcon";

const HeaderPublic = () => {
  const { isMobile } = useQueryMedia();

  return (
    <AppBar position="absolute" className="ac-nav">
      <Grid container item xs={12} alignItems="center">
        <Grid container item xs={4}>
          <Grid container item xs={2} sm={1} md={4.5} lg={4.5} xl={4.5}></Grid>
          <Grid container item xs={10} sm={11} md={7.5} lg={7.5} xl={7.5} alignItems="center">
            <Link to={Routes.ABOUT} className="item1 ac-nav__title">
              <LogoIcon className="ac-nav__logo" />
              {!isMobile && (
                <div className="ac-nav__title-text">
                  NON&nbsp;
                  <span className="ac-small">Meeting</span>
                </div>
              )}
            </Link>
          </Grid>
        </Grid>
        <Grid container item xs={8} />
      </Grid>
    </AppBar>
  );
};
export default HeaderPublic;
