import { Grid } from "@mui/material";
import LiveLectureTabs from "./LiveLectureTabs";

export default function LiveLecture() {
  return (
    <>
      <Grid container justifyContent="center" alignItems="flex-start" columnSpacing={2}>
        <Grid item xs={12}>
          <LiveLectureTabs />
        </Grid>
      </Grid>
    </>
  );
}
