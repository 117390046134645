import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import Colors from "../../../../utils/Colors";
import MessageType from "../../../../utils/MessageType";
import DialogMeetingCreateSuccess from "../../../shared/dialog-meeting-create-success/DialogMeetingCreateSuccess";

interface IAutocreateMeetingDialog {
  confirmAction?: () => void;
}

const AutocreateMeetingDialog = ({ confirmAction }: IAutocreateMeetingDialog) => {
  const { showMessage, selectedCourse } = useAppContext();
  const { hideModal, destroyModal, state, showModal } = useModal();
  const modalId = Object.keys(state)[0];

  const closeDialog = () => destroyModal(modalId);
  const hideDialog = () => hideModal(modalId);

  const saveMeeting = () => {
    const meetingTitle = `Temp-${moment().format("DD-MM-YYYY")}`;
    const parseStart = new Date(moment().valueOf()).toUTCString();
    const parseEnd = new Date(moment().add(1, "h").valueOf()).toUTCString();
    Lecture.createLecture(selectedCourse?.id, meetingTitle, parseStart, parseEnd)
      .then(async ({ data }: any) => {
        if (data?.message) {
          showMessage(data.message, MessageType.SUCCESS);
          if (confirmAction) {
            confirmAction();
          }
          hideDialog();
          showModal(DialogMeetingCreateSuccess, { meeting: data.data });
        } else {
          showMessage(data, MessageType.SUCCESS);
          console.error(data);
        }
      })
      .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
  };

  return (
    <Dialog open={true} fullWidth sx={{ textAlign: "center" }}>
      {/* <DialogTitleAnon color={Colors.BLACK} onClose={closeDialog}>
        {title}
      </DialogTitleAnon> */}
      <DialogContent>
        <Grid className="create-lecture" container item xs={12} xl={12} lg={12} sm={12} md={12} justifyContent="center" alignItems="center">
          <div>You don't have a scheduled meeting for this time. If you continue, we will create a meeting that will last for two hours. You can edit the meeting details in My Meeting.</div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="v-btn ac-color-white btn-send" sx={{ color: Colors.BLACK }} onClick={closeDialog}>
          Cancel
        </Button>
        <Button variant="contained" className="v-btn ac-color-white btn-send" sx={{ color: Colors.BLACK }} onClick={saveMeeting}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AutocreateMeetingDialog;
