import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Card, Divider, Grid, IconButton, Typography } from "@mui/material";
import SendInputMode from "../../../../constants/SendInputMode";
import { useQueryMedia } from "../../../../hooks/useQueryMedia";
import Colors from "../../../../utils/Colors";
import { getTitleQAD } from "../../../../utils/Utils";
import DislikeButton from "../../../shared/button/DislikeButton";
import LikeButton from "../../../shared/button/LikeButton";
import FlagComponent from "../../../shared/flag-component/FlagComponent";
import PadlockComponent from "../../../shared/padlock-component/PadlockComponent";
import RemovedFromClass from "../../../shared/removed-from-class/RemovedFromClass";
import SendInput from "../../../shared/send-input/SendInput";
import QuestionAnswersQuestionService, { QuestionAnswersQuestionServiceExport } from "./QuestionAnswersQuestionService";

export default function QuestionAnswersQuestion({ setQuestionPosted, locationState }: any) {
  const { question, loading, voteQuestion, toggleFlag, handleGoBack, handleSendAnswer, answerInput, setAnswerInput }: QuestionAnswersQuestionServiceExport = QuestionAnswersQuestionService({
    setQuestionPosted,
    locationState,
  });
  const { isMobile } = useQueryMedia();
  const { pseudonym, createdAt, selfPosted, selfFlagged, text, numUpVotes, numDownVotes, respondable, removedFromClass, teacherQuestion } = question;

  return (
    <>
      <Card sx={{ backgroundColor: `${Colors.QUESTION} !important`, padding: 1 }}>
        <Grid container justifyContent="center" alignItems="center" spacing={2} rowSpacing={2} columnSpacing={2}>
          {!isMobile && (
            <Grid item xs={!isMobile ? 1 : 0} textAlign="center" sx={{ borderRight: "1px solid white" }}>
              <IconButton size="large" onClick={handleGoBack}>
                <ArrowBackIcon sx={{ color: Colors.YELLOW_REACT }} fontSize="large" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={!isMobile ? 11 : 12}>
            <Grid container spacing={0.5}>
              <Grid container item xs="auto" columnGap={1} className="question-answers-q-title" alignItems="center">
                {isMobile && (
                  <IconButton sx={{ width: "18px", height: "18px", marginRight: 0 }} onClick={handleGoBack}>
                    <ArrowBackIcon sx={{ color: Colors.YELLOW_REACT }} />
                  </IconButton>
                )}
                <Typography variant="inherit">{getTitleQAD(pseudonym, teacherQuestion, createdAt)}</Typography>
                {removedFromClass && <RemovedFromClass />}
                <PadlockComponent respondable={respondable} />
                {!selfPosted && !teacherQuestion && <FlagComponent disabled={!respondable || loading.flag} selfFlagged={selfFlagged} handleToggleFlag={toggleFlag} />}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ backgroundColor: Colors.WHITE }} />
              </Grid>
              <Grid item xs={12} className="question-answers-q-answer">
                {text}
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={2} className="question-answers-q-reactions">
                <Grid container item xs={12} justifyContent="start" alignItems="start">
                  <LikeButton onClick={() => voteQuestion(1)} disabled={selfPosted || !respondable || loading.voteUp} label={numUpVotes} />
                  <DislikeButton onClick={() => voteQuestion(-1)} disabled={selfPosted || !respondable || loading.voteDown} label={numDownVotes} />
                </Grid>
              </Grid>
              {isMobile && (
                <Grid item xs={12}>
                  <Divider sx={{ backgroundColor: Colors.WHITE }} />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "0px !important" }}>
            <SendInput value={answerInput} setText={setAnswerInput} handleSend={handleSendAnswer} type={SendInputMode.A} isMobile={isMobile} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
