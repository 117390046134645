import SendIcon from "@mui/icons-material/Send";
import { ClickAwayListener, Grid, IconButton, TextField, Typography } from "@mui/material";
import SendInputMode from "../../../constants/SendInputMode";
import { useQueryMedia } from "../../../hooks/useQueryMedia";
import { SendInputType } from "../../../interfaces/Types";
import Colors from "../../../utils/Colors";
import UserRole from "../../../utils/UserRole";
import { makeRestrictions } from "../../../utils/Utils";
import CheckboxCustom from "../checkbox-custom/CheckboxCustom";
import SendInputService from "./SendInputService";

interface SendInputProps {
  value: string;
  handleSend: (teacherAsStudent: boolean) => void;
  disabled?: boolean;
  isMobile?: boolean;
  type?: SendInputType;
  myPlaceholder?: string;
  setText: any;
}

const SendInput = ({ value, handleSend, disabled, myPlaceholder, setText, type = SendInputMode.Q }: SendInputProps) => {
  const { handleInput, textValidationState, showSendData, setShowSendData, send, sendAsStudent, handleCheckbox } = SendInputService({ setText, handleSend, type });
  const { isTeacher } = UserRole();
  const { isMobile } = useQueryMedia();
  const isQuestion = type === SendInputMode.Q;
  const conn = isQuestion ? "a" : "an";
  const title = isQuestion ? "Question" : "Answer";
  return (
    <ClickAwayListener onClickAway={(e: any) => setTimeout(() => setShowSendData(false), 200)}>
      <Grid
        xs={12}
        container
        item
        p={1}
        mt={!isMobile ? 1 : 0}
        style={{ background: Colors.AZURE_RADIANCE, borderRadius: "4px", bottom: isMobile ? "0px" : "none", left: "0px" }}
        position={!isMobile ? "relative" : "fixed"}
        zIndex={1100}
      >
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <TextField
              value={value}
              multiline
              fullWidth
              disabled={disabled}
              size="small"
              placeholder={disabled ? `${title} are currently not allowed` : myPlaceholder ? myPlaceholder : `Post ${conn} ${title.toLocaleLowerCase()}/response`}
              className="input-comment"
              style={{ backgroundColor: "white", borderRadius: 5 }}
              onChange={handleInput}
              onFocus={() => setShowSendData(true)}
              inputProps={{ maxLength: makeRestrictions(type).chars }}
              InputProps={{
                style: { padding: "2px 0 2px 6px" },
                endAdornment: (
                  <IconButton onClick={send} disabled={!textValidationState?.validated} type="button" sx={{ p: "10px" }} aria-label="search">
                    <SendIcon style={{ color: textValidationState?.validated ? Colors.AZURE_RADIANCE : "grey" }} />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          {showSendData && (
            <Grid item container xs={12} display="flex" justifyContent="flex-start" alignItems="center">
              <Grid item xs={isMobile ? 8 : 9} style={{ color: textValidationState?.validated ? "white" : Colors.YELLOW_SOLID }}>
                <Typography sx={{ fontSize: isMobile ? 12 : 12 }} variant={"subtitle2"}>
                  {textValidationState?.text}
                </Typography>
              </Grid>
              {isTeacher && (
                <Grid item xs={isMobile ? 4 : 3} sx={{ color: "white" }}>
                  <CheckboxCustom label=" Post under a pseudonym" checked={sendAsStudent} onChange={handleCheckbox} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

export default SendInput;
