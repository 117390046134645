import { Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture } from "../../../../controller";
import DialogYesNoDataType from "../../../../interfaces/dialog/DialogYesNoDataType";
import LectureNumParams from "../../../../utils/LectureNumParams";
import MessageType from "../../../../utils/MessageType";
import Routes from "../../../../utils/Routes";
import { DATE_FORMAT, TIME_FORMAT_2 } from "../../../../utils/Utils";
import DialogYesNo from "../../../shared/dialog-yes-no/DialogYesNo";
import OutlinedInputCustom from "../../../shared/outlined-input-custom/OutlinedInputCustom";
import { fieldMessage, fieldType } from "./config";

interface ConfirmFunctionAndParameters {
  function: Function;
  parameters?: Array<any>;
}

interface LectureType {
  name?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  lectureNum?: number;
  allowQuestionsDuringLecture?: boolean;
  allowDiscussionsDuringLecture?: boolean;
}
interface LectureErrorType {
  name: boolean;
  date: boolean;
  startTime: boolean;
  endTime: boolean;
}

const AddLecture = () => {
  const baseClass = "create-lecture";
  const lectureNum = LectureNumParams();
  const history = useHistory();
  const { showMessage, selectedCourse } = useAppContext();
  const courseId = selectedCourse.id;
  const [showDialog, setShowDialog] = useState<DialogYesNoDataType>({ open: false, title: "" });
  const [confirmActionFunc, setConfirmActionFunc] = useState<ConfirmFunctionAndParameters>();
  const [lecture, setLecture] = useState<LectureType>();
  const [lectureError, setLectureError] = useState<LectureErrorType>({ name: false, date: false, startTime: false, endTime: false });

  const fetchData = async () => {
    if (lectureNum) {
      const lectureTemp = await Lecture.getLecture(courseId, lectureNum);
      setLecture({
        date: lectureTemp ? moment(lectureTemp?.startTime).format(DATE_FORMAT) : moment(new Date()).format(DATE_FORMAT),
        startTime: lectureTemp ? moment(lectureTemp?.startTime).format(TIME_FORMAT_2) : moment(new Date()).format(TIME_FORMAT_2),
        endTime: lectureTemp ? moment(lectureTemp?.endTime).format(TIME_FORMAT_2) : moment(new Date()).add(1, "hour").format(TIME_FORMAT_2),
        name: lectureTemp?.name ?? "",
        lectureNum: lectureTemp?.lectureNum,
        allowQuestionsDuringLecture: lectureTemp?.allowQuestionsDuringLecture,
        allowDiscussionsDuringLecture: lectureTemp?.allowDiscussionsDuringLecture,
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse]);

  const handleOnSubmit = (lectureDate: string, startTime: string, endTime: string, lectureTitle: string, allowQA?: boolean, allowDisc?: boolean) => {
    if (onBlurCheck()) return;
    const start = lectureDate + "T" + startTime;
    const end = lectureDate + "T" + endTime;
    const parseStart = new Date(start).toUTCString();
    const parseEnd = new Date(end).toUTCString();

    if (lectureNum) {
      Lecture.updateLecture(courseId, lecture?.lectureNum, lectureTitle, parseStart, parseEnd)
        .then(async ({ data }: any) => {
          if (data?.message) {
            showMessage(data.message, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
          } else {
            showMessage(data, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
            console.error(data);
          }
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    } else {
      Lecture.createLecture(courseId, lectureTitle, parseStart, parseEnd)
        .then(async ({ data }: any) => {
          if (data?.message) {
            showMessage(data.message, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
          } else {
            showMessage(data, MessageType.SUCCESS);
            history.push(Routes.TEACHER_MY_LECTURES);
            console.error(data);
          }
        })
        .catch((err: any) => showMessage(err.response?.data, MessageType.ERROR));
    }
  };

  const deleteLecture = (lectureNum: number) => {
    Lecture.deleteLecture(courseId, lectureNum)
      .then(() => {
        showMessage("Meeting deleted", MessageType.SUCCESS);
        history.push(Routes.TEACHER_MY_LECTURES);
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteLecture = () => {
    setShowDialog({ open: true, title: "Are you sure that you want to delete this meeting?", content: <Typography>{lecture?.name ?? lectureNum}</Typography> });
    setConfirmActionFunc({ function: deleteLecture, parameters: [lectureNum] });
  };

  const onBlurCheck = (ft?: string) => {
    if (ft) {
      ft === fieldType.NAME && setLectureError({ ...lectureError, name: !lecture?.name });
      ft === fieldType.DATE && setLectureError({ ...lectureError, date: !lecture?.date });
      ft === fieldType.START && setLectureError({ ...lectureError, startTime: !lecture?.startTime });
      ft === fieldType.END && setLectureError({ ...lectureError, endTime: !lecture?.endTime });
    } else {
      setLectureError({
        name: !lecture?.name,
        date: !lecture?.date,
        startTime: !lecture?.startTime,
        endTime: !lecture?.endTime,
      });
    }
    return !lecture?.name || !lecture?.date || !lecture?.startTime || !lecture?.endTime;
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid item>
        <div className={`${baseClass}__heading`}>{lectureNum ? "Edit meeting" : "Add a new meeting"}</div>
      </Grid>
      <Grid item xs={12} xl={11} lg={11} sm={9} md={9} mt={4}>
        <div className={baseClass}>
          <Grid container item xs={12} spacing={1} pb={1}>
            <Grid container item xs={12} xl={5} lg={5} sm={12} md={12} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>Meeting Title:</div>
              <OutlinedInputCustom
                id="lectureTitle"
                placeholder="Write the title of the meeting here"
                name="lectureTitle"
                value={lecture?.name}
                onChange={(e: any) => {
                  setLecture({ ...lecture, name: e.target.value ?? "" });
                }}
                aria-describedby="outlined-pass-helper-text"
                error={lectureError?.name}
                onBlur={() => onBlurCheck(fieldType.NAME)}
                fullWidth
                className={`${baseClass}__text-field`}
              />
              {lectureError?.name && (
                <div className="error-text">
                  <label>{fieldMessage.NAME}</label>
                </div>
              )}
            </Grid>
            <Grid container item xs={12} xl={2.5} lg={2.5} sm={12} md={2.5} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>Meeting Date:</div>
              <OutlinedInputCustom
                id="lectureDate"
                type="date"
                placeholder="Pick a Start Date"
                name="lectureDate"
                aria-describedby="outlined-pass-helper-text"
                value={lecture?.date}
                onChange={(e: any) => {
                  setLecture({ ...lecture, date: e.target.value ?? "" });
                }}
                onBlur={() => onBlurCheck(fieldType.DATE)}
                fullWidth
                className={`${baseClass}__text-field`}
                error={lectureError?.date}
              />
              {lectureError?.date && (
                <div className="error-text">
                  <label>{fieldMessage.DATE}</label>
                </div>
              )}
            </Grid>
            <Grid container item xs={12} xl={2.25} lg={2.25} sm={12} md={4.25} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>Start Time:</div>
              <OutlinedInputCustom
                id="startTime"
                type="time"
                placeholder="Pick a Start Date"
                name="startTime"
                value={lecture?.startTime}
                onChange={(e: any) => {
                  setLecture({ ...lecture, startTime: e.target.value ?? "" });
                }}
                aria-describedby="outlined-pass-helper-text"
                error={lectureError?.startTime}
                onBlur={() => onBlurCheck(fieldType.START)}
                fullWidth
                className={`${baseClass}__text-field`}
              />
              {lectureError?.startTime && (
                <div className="error-text">
                  <label>{fieldMessage.START}</label>
                </div>
              )}
            </Grid>
            <Grid container item xs={12} xl={2.25} lg={2.25} sm={12} md={4.25} justifyContent="center" alignItems="center">
              <div className={`${baseClass}__title`}>End Time:</div>
              <OutlinedInputCustom
                id="endTime"
                type="time"
                placeholder="Pick a Start Date"
                name="endTime"
                value={lecture?.endTime}
                onChange={(e: any) => {
                  setLecture({ ...lecture, endTime: e.target.value ?? "" });
                }}
                aria-describedby="outlined-pass-helper-text"
                error={lectureError?.endTime}
                onBlur={() => onBlurCheck(fieldType.END)}
                fullWidth
                className={`${baseClass}__text-field`}
              />
              {lectureError?.endTime && (
                <div className="error-text">
                  <label>{fieldMessage.END}</label>
                </div>
              )}
            </Grid>
          </Grid>
          <DialogYesNo dialogData={showDialog} setDialogData={setShowDialog} confirmAction={confirmActionFunc} />
          {/* {!!lectureNum && (
            <Grid container item justifyContent="center" alignItems="center" style={{ marginBottom: "37px" }}>
              <Grid item xs={6}>
                <Typography className={`${baseClass}__title`}>Allow Q&A</Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2">No</Typography>
                  <SwitchIOS
                    value={lecture?.allowQuestionsDuringLecture}
                    onChange={(e: any, checked: boolean) => setLecture({ ...lecture, allowQuestionsDuringLecture: checked })}
                    activeColor={Colors.GREEN_SOLID}
                    deactiveColor={Colors.RED_SOLID}
                  />
                  <Typography variant="subtitle2">Yes</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography className={`${baseClass}__title`}>Allow discussions</Typography>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="subtitle2">No</Typography>
                  <SwitchIOS
                    value={lecture?.allowDiscussionsDuringLecture}
                    onChange={(e: any, checked: boolean) => setLecture({ ...lecture, allowDiscussionsDuringLecture: checked })}
                    activeColor={Colors.GREEN_SOLID}
                    deactiveColor={Colors.RED_SOLID}
                  />
                  <Typography variant="subtitle2">Yes</Typography>
                </Box>
              </Grid>
            </Grid>
          )} */}
          <Grid container item xs={12} flexDirection="row" justifyContent={!lecture?.lectureNum ? "center" : "space-around"}>
            <Grid container item xs={12} xl={5} lg={5} sm={12} md={5} justifyContent="center" pt={1}>
              <Button
                onClick={() => handleOnSubmit(lecture?.date!, lecture?.startTime!, lecture?.endTime!, lecture?.name!, lecture?.allowQuestionsDuringLecture, lecture?.allowDiscussionsDuringLecture)}
                variant="contained"
                className="v-btn ac-color-white btn-send"
              >
                {`${lectureNum ? "Save Meeting" : "Save"}`}
              </Button>
            </Grid>
            {lecture?.lectureNum && (
              <Grid container item xs={12} xl={5} lg={5} sm={12} md={5} justifyContent="center" pt={1}>
                <Button variant="contained" className="v-btn ac-color-white btn-delete" onClick={handleDeleteLecture}>
                  Delete Meeting
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddLecture;
