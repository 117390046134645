export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL ?? "https://api-awsdev.accountanimity.com";

export * from "./auth";
export * from "./notification";
export * from "./topic";
export * from "./utils";

export const MAX_PRESENTATION_SIZE = 10; // 10 mb
export const MAX_QUESTION_LENGTH = 250;
export const MIN_QUESTION_LENGTH = 5;
export const MAX_QUESTION_WORDS = 50;
export const SOCKET_ID_HISTORY_KEY = "socket_id_history";
export const INIT_PAGE_NUM = 1;
