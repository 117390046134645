import axios from "axios";
import { BACKEND_BASE_URL } from "../../constants";
import { getAccessToken } from "../../utilsVue";

export const API_BASE_URL = `${BACKEND_BASE_URL}/apiMeet`;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      let token = getAccessToken();
      if (token) {
        config.headers["authorization"] = "Bearer " + token;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => {
  return axiosInstance;
};

const http = axiosInstance;
export { http };
