import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import { ClickAwayListener, Grid, IconButton, TextField, Typography } from "@mui/material";
import useExtendLecture from "../../../../../hooks/useExtendLecture";
import { useQueryMedia } from "../../../../../hooks/useQueryMedia";
import LectureDto from "../../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../../utils/Colors";
import EnumExpressionsText from "../../../../../utils/enum/EnumExpressionsText";
import EnumLectureState from "../../../../../utils/enum/EnumLectureState";
import LectureNumParams from "../../../../../utils/LectureNumParams";
import UserRole from "../../../../../utils/UserRole";
import { isLectureLive } from "../../../../../utils/Utils";
import DialogYesNo from "../../../../shared/dialog-yes-no/DialogYesNo";
import RemoteItem from "./RemoteItem";
import RemoteService, { RemoteServiceExport } from "./RemoteService";
import { StyledButton } from "./styled";

interface RemoteProps {
  lecture: LectureDto;
  currentSlide: number;
  setCurrentSlide: (val: number) => void;
}

const msgCreatedLecture = "Please wait for the host to start the meeting";
const msgEndLecture = "The meeting is not live so you cannot provide feedback at the moment. Please choose the Q&A option if you would like to post a question/answer or enter discussions.";
export default function Remote({ lecture, currentSlide, setCurrentSlide }: RemoteProps) {
  const {
    expressionData,
    expressionState,
    handleExpressionClick,
    handleResetClick,
    loadingReset,
    showSpeakerNameInput,
    setShowSpeakerNameInput,
    speakerName,
    setSpeakerName,
    handleSetSpeakerName,
    currentSpeakerName,
  }: RemoteServiceExport = RemoteService({
    lecture,
    currentSlide,
    setCurrentSlide,
  });
  const { isTeacher } = UserRole();
  const lectureNum = LectureNumParams();
  const { isMobile } = useQueryMedia();
  const { showDialog, setShowDialog, confirmActionFunc, rejectActionFunc } = useExtendLecture({ lectureNum });

  const displayText = (text: string) => (
    <Typography variant="h6" textAlign="center">
      {text}
    </Typography>
  );
  const createRemoteItem = (name: string, title: string) => <RemoteItem expressionData={expressionData} onClick={handleExpressionClick} expressionState={expressionState} name={name} title={title} />;

  return (
    <div className="remote">
      {lecture.state === EnumLectureState.CREATED && displayText(msgCreatedLecture)}
      {(lecture.state === EnumLectureState.ENDED || !isLectureLive(lecture)) && displayText(msgEndLecture)}
      {lecture.state === EnumLectureState.STARTED && isLectureLive(lecture) && (
        <>
          <div className="remote__container">
            <div className="remote__column" style={{ marginRight: "10px" }}>
              <div className="remote-item"></div>
              {createRemoteItem(EnumExpressionsText.REW, "Off Point")}
              <div className="remote-item"></div>
            </div>
            <div className="remote__column" style={{ marginTop: "-1px", paddingBottom: "15px" }}>
              {createRemoteItem(EnumExpressionsText.LOST, "Disagree")}
              {createRemoteItem(EnumExpressionsText.PAUSE, "Not Clear")}
              {createRemoteItem(EnumExpressionsText.GREAT, "Agree")}
            </div>
            <div className="remote__column" style={{ marginLeft: "10px" }}>
              <div className="remote-item"></div>
              {createRemoteItem(EnumExpressionsText.FF, "Wrap Up")}
              <div className="remote-item"></div>
            </div>
          </div>
          {currentSlide > 0 && isTeacher && (
            <StyledButton onClick={() => setShowSpeakerNameInput(true)} variant="contained">
              {`${isTeacher ? "F" : "Your f"}eedback to speaker: ${currentSpeakerName ?? currentSlide}`}
            </StyledButton>
          )}

          {currentSlide > 0 && !isTeacher && <div className="remote__title">{`${isTeacher ? "F" : "Your f"}eedback to speaker: ${currentSpeakerName ?? currentSlide}`}</div>}
          <div className="remote__reset-container">
            {isTeacher && (
              <LoadingButton
                variant="contained"
                loading={loadingReset}
                disabled={loadingReset}
                loadingPosition="start"
                sx={{ width: "130px" }}
                onClick={handleResetClick}
                className="remote__reset-button"
              >
                RESET
              </LoadingButton>
            )}
          </div>
          {showSpeakerNameInput && (
            <ClickAwayListener onClickAway={(e: any) => setTimeout(() => setShowSpeakerNameInput(false), 200)}>
              <Grid
                xs={12}
                container
                item
                p={1}
                mt={!isMobile ? 1 : 0}
                style={{ background: Colors.AZURE_RADIANCE, borderRadius: "2px", bottom: isMobile ? "0px" : "none", left: "0px", padding: isMobile ? "3px" : "8px" }}
                position={!isMobile ? "relative" : "fixed"}
                zIndex={1100}
              >
                <Grid item container xs={12} spacing={0}>
                  <Grid item xs={12} spacing={0}>
                    <TextField
                      value={speakerName}
                      multiline
                      fullWidth
                      size="small"
                      placeholder="Speaker name"
                      className="input-comment"
                      style={{ backgroundColor: "white", borderRadius: 5 }}
                      onChange={(e: any) => setSpeakerName(e.target.value ?? "")}
                      InputProps={{
                        style: { padding: 0, paddingLeft: 10 },
                        endAdornment: (
                          <IconButton onClick={handleSetSpeakerName} type="button" sx={{ p: "10px" }} aria-label="search">
                            <SendIcon style={{ color: Colors.AZURE_RADIANCE }} />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ClickAwayListener>
          )}
        </>
      )}
      <DialogYesNo dialogData={showDialog} setDialogData={setShowDialog} confirmAction={confirmActionFunc} rejectAction={rejectActionFunc} />
    </div>
  );
}
