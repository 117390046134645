import { useHistory, useLocation } from "react-router-dom";
import { PrivateRouteStudent } from "./components/route/PrivateRouteStudent";
import { PrivateRouteTeacher } from "./components/route/PrivateRouteTeacher";
import { PublicRoute } from "./components/route/PublicRoute";
import HeaderPublic from "./components/shared/header-public/HeaderPublic";
import Header from "./components/shared/header/Header";
import { useAppContext } from "./context/AuthContext";
import { http } from "./controller/http";
import MessageType from "./utils/MessageType";
import Routes from "./utils/Routes";
import UserRole from "./utils/UserRole";

export default function App() {
  const history = useHistory();
  const location = useLocation();
  const { accessToken, deleteAccessToken, showMessage } = useAppContext();
  const { isTeacher } = UserRole();
  const routeTeacher = isTeacher || location.pathname.includes("/teacher");

  http.interceptors.response.use(
    (response) => response,
    (err) => {
      const res = err.response;
      if (!res) {
        showMessage("Network error", MessageType.ERROR);
      } else if (!res.status) {
        showMessage("Network error, no response status", MessageType.ERROR);
      } else if (res.status === 403) {
        showMessage(res.data, MessageType.ERROR);
      } else if (res.status === 401) {
        showMessage(res.data, MessageType.ERROR);
        deleteAccessToken();
        history.push(Routes.ABOUT);
      }
      return Promise.reject(err);
    }
  );

  return (
    <div className="v-application">
      {!accessToken && <HeaderPublic />}
      {!location.pathname.endsWith("/presentation") && accessToken && <Header />}
      {accessToken ? routeTeacher ? <PrivateRouteTeacher /> : <PrivateRouteStudent /> : <PublicRoute isTeacher={routeTeacher} />}
    </div>
  );
}
