import { Box, Grid, Typography } from "@mui/material";
import SwitchIOS from "../../../../shared/switch-ios/SwitchIOS";
import ClassPermissionService, { ClassPermissionServiceType } from "./ClassPermissionService";

export default function ClassPermission({ liveLecture, setLiveLecture, sideDisplay }: any) {
  const { onChangeSwitch, ALLOW_QUESTIONS, ALLOW_DISCUSSIONS }: ClassPermissionServiceType = ClassPermissionService({ liveLecture, setLiveLecture });

  const upperCase = { typography: "subtitle2", textTransform: "uppercase", ...(sideDisplay && { fontSize: "12px" }) };

  return (
    <div className="class-status">
      <Grid container rowGap={sideDisplay ? 0 : 2}>
        <Grid item xs={12}>
          <Box sx={upperCase} className={`class-status-text ${!sideDisplay ? "permission-container" : ""}`}>
            {sideDisplay ? "Permissions" : "During the meeting allow:"}
          </Box>
        </Grid>
        <Grid item xs={sideDisplay ? 12 : 6}>
          <Typography variant="subtitle2" className="class-status-text">
            Q/A
          </Typography>
          <div className="permission-yes-no">
            <Typography variant="subtitle2" className="class-status-text">
              No
            </Typography>
            <SwitchIOS small={sideDisplay} name={ALLOW_QUESTIONS} value={liveLecture?.allowQuestionsDuringLecture} onChange={onChangeSwitch} disabled={!liveLecture?.lectureNum} />
            <Typography variant="subtitle2" className="class-status-text">
              Yes
            </Typography>
          </div>
        </Grid>
        {sideDisplay && <hr className="class-status-card-divider-slide-display" />}
        <Grid item xs={sideDisplay ? 12 : 6} style={{ borderLeft: !sideDisplay ? "1px solid #EED879" : "" }}>
          <Box sx={upperCase} className="class-status-text">
            Discussions
          </Box>
          <div className="permission-yes-no">
            <Typography variant="subtitle2" className="class-status-text">
              No
            </Typography>
            <SwitchIOS small={sideDisplay} name={ALLOW_DISCUSSIONS} value={liveLecture?.allowDiscussionsDuringLecture} onChange={onChangeSwitch} disabled={!liveLecture?.lectureNum} />
            <Typography variant="subtitle2" className="class-status-text">
              Yes
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
