import { Grid, IconButton, Typography } from "@mui/material";
import SendInputMode from "../../../../../constants/SendInputMode";
import { useQueryMedia } from "../../../../../hooks/useQueryMedia";
import LectureDto from "../../../../../interfaces/lecture/LectureDto";
import QuestionType from "../../../../../interfaces/question/QuestionType";
import { sortListByItem } from "../../../../../utils/Utils";
import ArrowIcon from "../../../../shared/icons/ArrowIcon";
import QuestionCard from "../../../../shared/question-card/QuestionCard";
import { RefreshComponent } from "../../../../shared/refresh-component/RefreshComponent";
import SendInput from "../../../../shared/send-input/SendInput";
import LiveLectureQuestionService, { LiveLectureQuestionServiceExport } from "./LiveLectureQuestionService";

interface LiveLectureQuestionProps {
  lecture: LectureDto;
  currentSlide: number;
  sortBy: number;
  tab: number;
  disabled?: boolean;
}

export default function LiveLectureQuestion({ currentSlide, sortBy, lecture, tab }: LiveLectureQuestionProps) {
  const { questions, flagged, text, setText, handleSendQuestion, callbackUpdateState, showRefreshComponent, fetchData, expand, handleExpand }: LiveLectureQuestionServiceExport =
    LiveLectureQuestionService({ currentSlide });
  const { isMobile } = useQueryMedia();
  const flaggedExists = flagged.length > 0;

  return (
    <>
      {showRefreshComponent && <RefreshComponent refreshAction={fetchData} />}
      <Grid container xs={12} item justifyContent="space-between" alignItems="center" flexDirection="column">
        <Grid xs={12} sm={12} md={9} xl={7} lg={7} container item flexDirection="row" alignItems="center" justifyContent="center" maxHeight={isMobile ? undefined : 600} overflow="auto">
          <Grid
            container
            item
            justifyContent="center"
            alignItems="flex-start"
            alignContent="start"
            xs={12}
            spacing={1}
            minHeight={"400px !important"}
            style={{ paddingBottom: isMobile && !flaggedExists ? "50px" : "0px" }}
            pr={1}
          >
            {sortListByItem(questions, sortBy).map((q: QuestionType) => (
              <Grid key={q.id} item xs={12}>
                <QuestionCard question={q} tabValue={tab} callbackRefresh={callbackUpdateState} showEditable={true} />
              </Grid>
            ))}
          </Grid>
          {flaggedExists && (
            <Grid xs={12} container item flexDirection="row" justifyContent="space-between" alignItems="center" mb={isMobile ? 7 : 0}>
              <Grid container item xs={12} justifyContent="center">
                <IconButton onClick={handleExpand}>
                  <ArrowIcon rotate={expand ? 180 : 0} edgeLength="1.3rem" />
                </IconButton>
              </Grid>
              <Grid container item xs={3.5} sm={4} md={4} xl={4.5} lg={4.5} style={{ height: 1, backgroundColor: "white", width: "100%" }} />
              <Grid container item xs={4} sm={4} md={4} xl={3} lg={3} justifyContent="center">
                <Typography fontSize={11}>Flagged questions</Typography>
              </Grid>
              <Grid container item xs={3.5} sm={4} md={4} xl={4.5} lg={4.5} style={{ height: 1, backgroundColor: "white", width: "100%" }} />

              <Grid sx={{ display: expand ? "flex" : "none" }} container item justifyContent="center" alignItems="flex-start" alignContent="start" xs={12} spacing={1}>
                {sortListByItem(flagged, sortBy).map((q: QuestionType) => (
                  <Grid key={q.id} item xs={12}>
                    <QuestionCard question={q} tabValue={tab} callbackRefresh={callbackUpdateState} showEditable={true} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12} sm={12} md={9} xl={7} lg={7} mt={1} flexDirection="column">
          <SendInput
            value={text}
            setText={setText}
            handleSend={handleSendQuestion}
            disabled={!lecture.isAttending || !lecture.allowQuestionsDuringLecture}
            isMobile={isMobile}
            type={SendInputMode.Q}
            myPlaceholder="Post a question/comment"
          />
        </Grid>
      </Grid>
    </>
  );
}
