import { Grid, Typography } from "@mui/material";
import React from "react";
import { useQueryMedia } from "../../../../../hooks/useQueryMedia";
import OutlinedInputCustom from "../../../../shared/outlined-input-custom/OutlinedInputCustom";
import TooltipStyled from "../../../../shared/tooltip/TooltipStyled";
import { ACC_LECTURE, PSEUDONYMS } from "../config";
import ClassInfoService, { ClassInfoServiceExport } from "./ClassInfoService";

interface ClassInfoDataProps {
  value: number;
  tooltipText: string;
  text: string;
}

export default function ClassInfo({ lectureNum }: { lectureNum?: number }) {
  const { classInfoObj }: ClassInfoServiceExport = ClassInfoService({ lectureNum });
  const { isMobile } = useQueryMedia();
  const classInfoData: ClassInfoDataProps[] = [
    { value: classInfoObj.pseudonyms, ...PSEUDONYMS },
    { value: classInfoObj.accountsInLecture, ...ACC_LECTURE },
  ];

  return (
    <Grid container rowSpacing={isMobile ? 1 : 4.5}>
      {classInfoData.map(({ text, value, tooltipText }: ClassInfoDataProps, i: number) => (
        <React.Fragment key={i}>
          <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
            <TooltipStyled tooltipText={tooltipText}>
              <Typography sx={{ cursor: "pointer" }} style={{ textAlign: "left" }} fontSize={isMobile ? 12 : 14}>
                {text}
              </Typography>
            </TooltipStyled>
          </Grid>
          <Grid item xs={2}>
            <OutlinedInputCustom className="text-field" inputProps={{ style: { textAlign: "center" } }} readOnly={true} value={value} />
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}
