import { mdiLock } from "@mdi/js";
import { SvgIcon } from "@mui/material";
import TooltipStyled from "../tooltip/TooltipStyled";

interface PadlockComponentType {
  respondable: boolean;
  color?: string;
}
export default function PadlockComponent({ respondable, color }: PadlockComponentType) {
  if (respondable) return null;
  return (
    <TooltipStyled placement="bottom" tooltipText="View only. You didn't attend this meeting.">
      <SvgIcon sx={{ verticalAlign: "top", ml: 1, mb: 0.3, color: color ?? "white", fontSize: "1.1rem" }}>
        <path d={mdiLock} />
      </SvgIcon>
    </TooltipStyled>
  );
}
