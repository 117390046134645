import { Card, CardHeader, Divider, Grid, IconButton, Typography } from "@mui/material";
import DetailsType from "../../../../interfaces/notification/DetailsType";
import NotificationType from "../../../../interfaces/notification/NotificationType";
import Colors from "../../../../utils/Colors";
import EnumNotificationEvent from "../../../../utils/enum/EnumNotificationEvent";
import { getCreateUpdateDate, getTitleQAD } from "../../../../utils/Utils";
import { NotificationCardContent } from "./styled";

type NotificationListType = {
  notificationList: NotificationType[];
  acknowledgeById: (id: number) => void;
  handleNotificationClick: (notification: NotificationType) => void;
};

const QUESTION_EVENTS: string[] = [EnumNotificationEvent.QUESTION_POSTED, EnumNotificationEvent.QUESTION_FLAGGED];
const ANSWER_EVENTS: string[] = [EnumNotificationEvent.QUESTION_ANSWERED, EnumNotificationEvent.ANSWER_FLAGGED];

export default function NotificationList({ notificationList, acknowledgeById, handleNotificationClick }: NotificationListType) {
  const getDetailsData = (event: string, { question, answer }: DetailsType) => {
    const questionText = `Q: ${question.text}`;
    const answerText = `${"\n"}A: ${answer?.text ?? ""}`;
    if (QUESTION_EVENTS.includes(event)) {
      return questionText;
    } else if (ANSWER_EVENTS.includes(event)) {
      return `${questionText}${answerText}`;
    } else if (EnumNotificationEvent.DISCUSSION_STARTED === event) {
      return `${questionText}${answerText}${"\n"}D: New discussion started`;
    } else if (EnumNotificationEvent.DISCUSSION_REPLY === event) {
      return `${questionText}${answerText}${"\n"}D: New message, click for details`;
    } else {
      return "";
    }
  };

  const getTitle = (event: string, { pseudonym, lecture, question, answer }: DetailsType, createdAt: string, updatedAt: string) => {
    const isQuestion = QUESTION_EVENTS.includes(event);
    const isAnswer = ANSWER_EVENTS.includes(event);
    const teacherPost = isQuestion ? question.teacherQuestion : isAnswer ? answer?.teacherAnswer : false;
    return `${lecture.name}, ${getTitleQAD(pseudonym, teacherPost, getCreateUpdateDate(createdAt, updatedAt))}`;
  };

  return (
    <>
      {notificationList.map(({ id, event, acknowledged, details, createdAt, updatedAt }: NotificationType) => (
        <Grid key={id} item xs={12} paddingBottom={1}>
          <Card sx={{ backgroundColor: acknowledged ? Colors.QUESTION : Colors.DARK_BLUE, color: Colors.WHITE }}>
            <CardHeader
              sx={{ paddingTop: 1, paddingBottom: 0, paddingInline: 1 }}
              action={
                !acknowledged && (
                  <IconButton onClick={() => acknowledgeById(id)}>
                    <div style={{ background: Colors.RED_SOLID, width: "12px", height: "12px", borderRadius: "6px" }} />
                  </IconButton>
                )
              }
              subheader={
                <Typography sx={{ fontSize: 10 }} gutterBottom>
                  {getTitle(event, details, createdAt, updatedAt)}
                </Typography>
              }
            />
            <NotificationCardContent onClick={() => handleNotificationClick({ id, event, acknowledged, details, createdAt, updatedAt })}>
              <>
                <Divider sx={{ backgroundColor: Colors.WHITE, marginY: 0.5 }} />
                <Typography sx={{ whiteSpace: "pre-wrap" }} variant="subtitle2" component="div">
                  {getDetailsData(event, details)}
                </Typography>
              </>
            </NotificationCardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
}
