import { useModal } from "mui-modal-provider";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../../../context/AuthContext";
import { Lecture, RemoteSocket } from "../../../../controller";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Routes from "../../../../utils/Routes";
import { isLectureLive, useEffectOnce } from "../../../../utils/Utils";
import AutocreateMeetingDialog from "../add-lecture/AutocreateMeetingDialog";

interface ClassStatusServiceType {
  liveLecture: LectureDto | undefined;
  setLiveLecture: any;
  handleEndLecture: any;
  handleContinuePresentation: any;
  newConnectionWarning: boolean;
  setNewConnectionWarning: (value: boolean) => void;
  closeWarningStartLecture: any;
  getLectures: () => void;
  startMeeting: () => void;
}

export default function ClassStatusService(): ClassStatusServiceType {
  const history = useHistory();
  const { selectedCourse } = useAppContext();
  const { showModal } = useModal();
  const courseId = selectedCourse.id;
  const [liveLecture, setLiveLecture] = useState<LectureDto>();
  const [newConnectionWarning, setNewConnectionWarning] = useState<boolean>(false);

  useEffectOnce(() => {
    getLectures();
  });

  const getLectures = async () => {
    const { data } = await Lecture.getCourseLectures(courseId);
    if (data?.message) {
      const findLiveLecture = data.data.find(isLectureLive);
      setLiveLecture(findLiveLecture);
    }
  };

  const openTeacherRemote = () => history.push(Routes.TEACHER_REMOTE.replace(":lectureNum", String(liveLecture?.lectureNum)));
  const startLecture = (callback: any) => {
    Lecture.startLecture(courseId, liveLecture?.lectureNum)
      .then((res: any) => callback())
      .catch((err: any) => console.log(err));
  };

  const handleOpenTeacherRemote = async () => startLecture(() => openTeacherRemote());
  const handleStartLecture = async () => {
    handleOpenTeacherRemote();
  };

  const handleEndLecture = async () => {
    await Lecture.endLecture(courseId, liveLecture?.lectureNum);
    RemoteSocket.disconnect();
    getLectures();
  };

  const handleContinuePresentation = () => openTeacherRemote();

  const closeWarningStartLecture = () => {
    setNewConnectionWarning(false);
    handleStartLecture();
  };

  const startMeeting = () => {
    if (!liveLecture) {
      showModal(AutocreateMeetingDialog, { confirmAction: getLectures });
    } else {
      setNewConnectionWarning(true);
    }
  };

  return {
    liveLecture,
    setLiveLecture,
    handleEndLecture,
    handleContinuePresentation,
    newConnectionWarning,
    setNewConnectionWarning,
    closeWarningStartLecture,
    getLectures,
    startMeeting,
  };
}
export type { ClassStatusServiceType };
