import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { wsEvents } from "../../../../constants";
import { useAppContext } from "../../../../context/AuthContext";
import { Course, Lecture, SignalSocket } from "../../../../controller";
import LectureDto from "../../../../interfaces/lecture/LectureDto";
import Colors from "../../../../utils/Colors";
import EnumSignal from "../../../../utils/enum/EnumSignal";
import EnumSortItem from "../../../../utils/enum/EnumSortItem";
import EnumTabs from "../../../../utils/enum/EnumTabs";
import LectureNumParams from "../../../../utils/LectureNumParams";
import MessageType from "../../../../utils/MessageType";
import Routes from "../../../../utils/Routes";
import { isLectureLive, SIGNAL_REMOVED_FROM_CLASS, useEffectOnce } from "../../../../utils/Utils";
import SelectCustom from "../../../shared/select-custom/SelectCustom";
import LiveLectureQuestion from "./tab-question/LiveLectureQuestion";
import Remote from "./tab-remote/Remote";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const optionsSortBy = [
  { id: EnumSortItem.TIME, label: "Time" },
  { id: EnumSortItem.VOTE, label: "Votes" },
  { id: EnumSortItem.NUM_OF_ANSWERS, label: "Number of answers" },
];

const AntTabs = styled(Tabs)({
  borderRadius: "50px",
  backgroundColor: "#dbdcdc",
  minHeight: "37px",
  height: "37px",
});

const AntTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "uppercase",
  width: "50%",
  borderRadius: "50px",
  minHeight: "37px",
  height: "37px",
  color: "#000",
  "&:hover": {
    opacity: 0.8,
  },
  "&.Mui-selected": {
    color: "white",
    background: `linear-gradient(90deg,${Colors.LIGHT_BLUE_HEADER} 0,${Colors.DARK_BLUE_HEADER})`,
  },
}));

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box pl={{ xs: 0, sm: 0, md: 2, lg: 2, xl: 2 }} pr={{ xs: 0, sm: 0, md: 2, lg: 2, xl: 2 }} pt={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function LiveLectureTabs() {
  const { selectedCourse, selectedLecture, updateSelectedCourse, updateSelectedLecture, showMessage } = useAppContext();
  const history = useHistory();
  const lectureNum = LectureNumParams();
  const location: any = useLocation();
  const initTab = location?.state?.goToTab;
  const [tab, setTab] = useState<number>(initTab ?? EnumTabs.REMOTE);
  const [lecture, setLecture] = useState<LectureDto | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [sortBy, setSortBy] = useState<number>(optionsSortBy[0].id);

  const handleChangeTab = (e: React.SyntheticEvent, newValue: number) => {
    history.replace({
      pathname: Routes.LECTURE_LIVE.replace(":lectureNum", String(lectureNum)),
      state: { lectureNum: lectureNum, goToTab: newValue },
    });
    setTab(newValue);
  };
  const handleChangeSelect = (e: any) => setSortBy(e.target.value);

  useEffectOnce(() => {
    getLecture();
    SignalSocket.on(wsEvents.recv.SIGNAL, handleSignal);
    return () => {
      SignalSocket.off(wsEvents.recv.SIGNAL, handleSignal);
    };
  });

  const handleSignal = (signal: string) => {
    const updateLectureSignals: string[] = [EnumSignal.LECTURE_STARTED, EnumSignal.LECTURE_ENDED, EnumSignal.LECTURE_UPDATED];
    if (updateLectureSignals.includes(signal)) {
      getLecture();
    }
    if (SIGNAL_REMOVED_FROM_CLASS.includes(signal)) {
      history.push(Routes.OLD_OR_NEW_STUDENT);
      showMessage("You have been removed from meeting.", MessageType.ERROR);
    }
  };

  const getLecture = () => {
    setLoading(true);
    Lecture.getLecture(selectedCourse.id, lectureNum)
      .then(async (lectureLoaded) => {
        if (Boolean(!selectedCourse?.name)) {
          const course = await Course.getCourse(lectureLoaded.courseId);
          updateSelectedCourse(course.data.data);
        }
        if (Boolean(!selectedLecture)) {
          updateSelectedLecture(lectureLoaded);
        }
        setLecture(lectureLoaded);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      {loading ? (
        <Typography variant="h6">Meeting loading...</Typography>
      ) : !lecture ? (
        <Typography variant="h6">No meeting found</Typography>
      ) : (
        <>
          <Grid container item xs={12} sm={9} md={9} xl={7} lg={7} justifyContent="center" alignItems="center">
            {tab === EnumTabs.Q_A && <Grid item xs={2} sm={2} md={4} lg={4} xl={4}></Grid>}
            <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
              <AntTabs value={tab} onChange={handleChangeTab} aria-label="ant tabs" TabIndicatorProps={{ style: { display: "none" } }}>
                <AntTab label="Feedback" {...a11yProps(0)} disabled={!isLectureLive(lecture)} />
                <AntTab label="Q&A" {...a11yProps(1)} />
              </AntTabs>
            </Grid>
            {tab === EnumTabs.Q_A && (
              <Grid item xs={2} sm={2} md={4} lg={4} xl={4} textAlign="right" paddingRight={1}>
                <SelectCustom title="Sort by" value={sortBy} handleChange={handleChangeSelect} options={optionsSortBy} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tab} index={EnumTabs.REMOTE}>
              <Remote lecture={lecture} currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} />
            </TabPanel>
            <TabPanel value={tab} index={EnumTabs.Q_A}>
              <LiveLectureQuestion lecture={lecture} currentSlide={currentSlide} sortBy={sortBy} tab={tab} />
            </TabPanel>
          </Grid>
        </>
      )}
    </Grid>
  );
}
