import { Redirect, Route, Switch } from "react-router-dom";
import { useAppContext } from "../../context/AuthContext";
import Routes from "../../utils/Routes";
import FreeLayout from "../layout/free/FreeLayout";
import TeacherLayout from "../layout/teacher/TeacherLayout";
import HowItWorks from "../pages/student-and-teacher/how-it-works/HowItWorks";
import MyAnswersTabs from "../pages/student-and-teacher/my-answers/MyAnswersTabs";
import MyQuestionTabs from "../pages/student-and-teacher/my-questions/MyQuestionTabs";
import Notifications from "../pages/student-and-teacher/notifications/Notifications";
import Settings from "../pages/student-and-teacher/settings/Settings";
import MyDiscussionsTabs from "../pages/student/my-discussions/MyDiscussionsTabs";
import QuestionAnswers from "../pages/student/question-answers/QuestionAnswers";
import AddLecture from "../pages/teacher/add-lecture/AddLecture";
import ClassStatus from "../pages/teacher/class-status/ClassStatus";
import MyLectures from "../pages/teacher/my-lectures/MyLectures";
import StatisticsAdmin from "../pages/teacher/statistics-admin/StatisticsAdmin";
import SlideStatistics from "../pages/teacher/statistics/SlideStatistics";
import Statistics from "../pages/teacher/statistics/Statistics";
import Stats from "../pages/teacher/statistics/Stats";
import TeacherQuestions from "../pages/teacher/teacher-questions/TeacherQuestions";
import TeacherRemote from "../pages/teacher/teacher-remote/TeacherRemote";
import { FeedbackPage } from "../shared/feedback-page/FeedbackPage";

const teacherWrap = (component: JSX.Element) => <TeacherLayout>{component}</TeacherLayout>;
const freeWrap = (component: JSX.Element) => <FreeLayout>{component}</FreeLayout>;

export const PrivateRouteTeacher = () => {
  const { selectedCourse } = useAppContext();
  const courseId = selectedCourse?.id;
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to={Routes.TEACHER_MY_LECTURES} />} />
      {/* <Route exact path={Routes.TEACHER_MY_COURSE} component={() => teacherWrap(<MyCourses />)} />
      <Route exact path={Routes.TEACHER_MY_COURSES_ADD} component={() => teacherWrap(<AddCourse />)} />
      <Route exact path={Routes.TEACHER_MY_COURSES_EDIT} component={() => teacherWrap(<AddCourse />)} /> */}

      <Route exact path={Routes.TEACHER_SETTINGS} component={() => teacherWrap(<Settings />)} />
      <Route exact path={Routes.TEACHER_HOW_IT_WORKS} component={() => teacherWrap(<HowItWorks />)} />
      <Route exact path={Routes.TEACHER_REMOTE} component={() => freeWrap(<TeacherRemote />)} />
      <Route exact path={Routes.FEEDBACK} component={() => teacherWrap(<FeedbackPage />)} />
      {courseId && (
        <>
          <Route exact path={Routes.TEACHER_MY_LECTURES} component={() => teacherWrap(<MyLectures />)} />
          <Route exact path={Routes.TEACHER_MY_LECTURES_ADD} component={() => teacherWrap(<AddLecture />)} />
          <Route exact path={Routes.TEACHER_MY_LECTURES_EDIT} component={() => teacherWrap(<AddLecture />)} />
          <Route exact path={Routes.TEACHER_CLASS_STATUS} component={() => teacherWrap(<ClassStatus />)} />
          <Route exact path={Routes.TEACHER_MY_QUESTIONS} component={() => teacherWrap(<MyQuestionTabs />)} />
          <Route exact path={Routes.TEACHER_MY_ANSWERS} component={() => teacherWrap(<MyAnswersTabs />)} />
          <Route exact path={Routes.TEACHER_MY_DISCUSSIONS} component={() => teacherWrap(<MyDiscussionsTabs />)} />
          {/* <Route exact path={Routes.LECTURE_PRESENTATION} component={() => <Presentation />} /> */}
          <Route exact path={Routes.TEACHER_QUESTIONS} component={() => freeWrap(<TeacherQuestions />)} />
          <Route exact path={Routes.PAGE_QUESTION_ANSWERS} component={() => freeWrap(<QuestionAnswers />)} />
          <Route exact path={Routes.TEACHER_NOTIFICATIONS} component={() => teacherWrap(<Notifications />)} />
          <Route exact path={Routes.ADMIN_STATS} component={() => teacherWrap(<StatisticsAdmin />)} />
          <Route exact path={Routes.TEACHER_STATS} component={() => teacherWrap(<Statistics />)} />
          <Route exact path={Routes.TEACHER_SLIDE_STATS} component={() => freeWrap(<SlideStatistics />)} />
          <Route exact path={Routes.LECTURE_STATS} component={() => teacherWrap(<Stats />)} />
        </>
      )}
    </Switch>
  );
};
