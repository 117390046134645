import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  display: flex;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 auto;
  @media (max-width: 600px) {
    margin-top: -10px;
    margin-bottom: 5px;
  }
  margin-top: 35px;
`;
