const PSEUDONYMS = {
  text: "Pseudonyms issued in this meeting",
  tooltipText: ` This is the number of people present in the meeting. Please check if
  the number of people matches the number of pseudonyms. If it
  doesn't, please click the RESET button.`,
};
const ACC_LECTURE = {
  text: "New accounts issued in this meeting",
  tooltipText: `This is the number of AnonIDs issued in this meeting. The new AnonIDs
  should be issued only to people that have not attended any of the
  previous meetings. If the number of the new people
  doesn't match the number of new accounts, please click the RESET
  button.`,
};
const ACC_COURSE = {
  text: "New accounts issued in this course",
  tooltipText: `This is the number of AnonIDs issued in all the meetings of this
  course. Before allowing new accounts, please check if any participant has
  already received an AnonID in any of the previous lectures. If they
  did, they should use it to register to this course.`,
};
const REG_STUDENTS = {
  text: "Registered participants in this course",
  tooltipText: `This is the number of participants whose AnonID is registered for this
  course. Note that a participant can receive an AnonID in any course and
  use it to register for this course. Before allowing new accounts,
  please check if any participant has already received an AnonID.`,
};

export { PSEUDONYMS, ACC_LECTURE, ACC_COURSE, REG_STUDENTS };
