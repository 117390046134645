import { Box, Button, Grid } from "@mui/material";
import Colors from "../../../../../utils/Colors";
import OutlinedInputCustom from "../../../../shared/outlined-input-custom/OutlinedInputCustom";
import ClassIdService, { ClassIdServiceType } from "./ClassIdService";

export default function ClassId({ lectureNum, sideDisplay, getLectures }: any) {
  const { handleToggleState, codeValidUntil, currentClassCode }: ClassIdServiceType = ClassIdService({ lectureNum, getLectures });

  const upperCase = { typography: "subtitle2", textTransform: "uppercase", ...(sideDisplay && { fontSize: "12px" }) };

  const getLabel = () => {
    if (sideDisplay) {
      return currentClassCode === "" ? "Request ID" : "Stop";
    } else {
      return currentClassCode === "" ? "Request Meeting Code" : "Stop Display";
    }
  };

  return (
    <div className="class-status">
      <Grid container rowSpacing={sideDisplay ? 1 : 6} columnSpacing={sideDisplay ? 1 : 4}>
        {sideDisplay && (
          <Grid item xs={12}>
            <Box sx={upperCase}>Lecture Code</Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Button onClick={handleToggleState} variant="contained" style={{ backgroundColor: Colors.YELLOW_REACT, width: "70%", ...(sideDisplay && { padding: "1px" }) }} className="button-custom">
            {getLabel()}
          </Button>
        </Grid>
        <Grid className="class-status-lecture-container" item xs={6} sx={{ paddingTop: 0 }}>
          <OutlinedInputCustom inputProps={{ style: { textAlign: "center" } }} readOnly value={currentClassCode} className="text-field" />
        </Grid>
        <Grid className="class-status-lecture-container" item xs={6} sx={{ paddingTop: 0 }}>
          <OutlinedInputCustom inputProps={{ style: { textAlign: "center" } }} readOnly value={codeValidUntil + ` ${sideDisplay ? "s" : "sec"}`} className="text-field" />
        </Grid>
      </Grid>
    </div>
  );
}
